import axios from 'axios';
import Qs from 'qs';
import Config from '../config/Config';
import rootStore from '../stores/RootStore';

const axiosInstance = axios.create();

var sessionTimeout = null;

axiosInstance.interceptors.request.use(config => {
	const token = localStorage.getItem('token');
	const prepaidToken = sessionStorage.getItem('prepaidToken');

	if (!!token) {
		// Check if Authorization header is already provided (do not override!)
		if (!config.headers['Authorization']) {
			config.headers = {
				...config.headers,
				'Authorization': token
			};
		}

		// Refresh session timeout timer
		if (sessionTimeout !== null) {
			clearTimeout(sessionTimeout);
		}

		sessionTimeout = setTimeout(() => {
			rootStore.userStore.showSessionTimeoutWarning();
		}, (Config.SESSION_TIMEOUT - (5 * 60) ) * 1000);

		// TODO Maybe add request timeout using cancel tokens
		// https://github.com/axios/axios#cancellation
	}

	if (!!prepaidToken) {
		config.headers = {
			...config.headers,
			'X-OTP-Authorization': prepaidToken
		};
	}

	return config;
});

axiosInstance.interceptors.response.use(response => {
	return response;
}, error => {
	if (error && error.response && error.response.status === 401) {
		if (window.location.pathname !== '/account/signin') {
			window.location = '/account/signin';
		}
	}

	// TODO Handle API down (!error.response && error.request)

	return Promise.reject(error);
});

const requests = {
	noScope: () => {
		axiosInstance.defaults.baseURL = Config.API_ENDPOINT;

		return requests;
	},
	public: () => {
		axiosInstance.defaults.baseURL = Config.PUBLIC_API_ENDPOINT;

		return requests;
	},
	member: () => {
		axiosInstance.defaults.baseURL = Config.MEMBERS_API_ENDPOINT;

		return requests;
	},
	uaepass: () => {
		axiosInstance.defaults.baseURL = Config.UAEPASS_API_ENDPOINT;

		return requests;
	},
	legacy: () => {
		axiosInstance.defaults.baseURL = Config.LEGACY_API_ENDPOINT;

		return requests;
	},
	fazaa: () => {
		axiosInstance.defaults.baseURL = Config.NEW_FAZAA_API_ENDPOINT;

		return requests;
	},
	get: (url, params = null, config = {}) => {
		if (params !== null) {
			config.params = params;
			config.paramsSerializer = params => Qs.stringify(params, {
				arrayFormat: 'repeat'
			});
		}

		return axiosInstance.get(url, config);
	},
	post: (url, data, upload = false, onProgress = null, config = {}) => {
		if (upload) {
			config.headers = {
				...config.headers,
				'Content-Type': 'multipart/form-data'
			};

			if (!!onProgress) {
				config.onUploadProgress = onProgress;
			}

			return axiosInstance.post(url, data, config);
		} else {
			return axiosInstance.post(url, data, config);
		}
	},
	put: (url, data, config = {}) =>
		axiosInstance.put(url, data, config)
};

const Common = {
	languages: () =>
		requests.public().get('/languages'),
	i18n: () =>
		requests.public().get('/i18n'),
	i18nList: () =>
		requests.public().get('/i18n/list'),
	i18nTimestamp: () =>
		requests.public().get('/i18n/timestamp')
};

const Home = {
	featured: (position = null) => {
		if (rootStore.userStore.authenticated) {
			if (position) {
				return requests.member().get(`/api/home?latitude=${position.latitude}&longitude=${position.longitude}`);
			} else {
				return requests.member().get('/api/home');
			}
		} else {
			if (position) {
				return requests.public().get(`/home?latitude=${position.latitude}&longitude=${position.longitude}`);
			} else {
				return requests.public().get('/home');
			}
		}
	},
	hamadNews: () =>
		requests.public().get('/hamad-news'),
	offers: (position = null) => {
		if (rootStore.userStore.authenticated) {
			if (position) {
				return requests.member().get(`/api/home/offers?latitude=${position.latitude}&longitude=${position.longitude}`);
			} else {
				return requests.member().get('/api/home/offers');
			}
		} else {
			if (position) {
				return requests.public().get(`/home/offers?latitude=${position.latitude}&longitude=${position.longitude}`);
			} else {
				return requests.public().get('/home/offers');
			}
		}
	}
}

const Categories = {
	all: () =>
		requests.public().get('/categories'),
	featured: () =>
		requests.public().get('/categories/featured')
};

const Services = {
	all: () =>
		requests.public().get('/services'),
	get: slug =>
		requests.public().get(`/services/${slug}`),
	featured: () =>
		requests.public().get('/services/featured')
};

const Offers = {
	all: () =>
		requests.public().get('/offers'),
	get: slug =>
		requests.public().get(`/offers/slug/${slug}`),
	featured: () =>
		requests.public().get('/offers/featured'),
	getDynamic: slug =>
		requests.member().get(`/api/extra-offers/${slug}`)
};

const Vouchers = {
	all: () =>
		requests.public().get('/vouchers'),
	get: slug =>
		requests.public().get(`/vouchers/slug/${slug}`),
	featured: () =>
		requests.public().get('/vouchers/featured'),
	packages: () =>
		requests.member().get('/api/voucher-package'),
	package: slug =>
		requests.member().get(`/api/voucher-package/${slug}`),
	limits: id =>
		requests.member().get(`/api/voucher-package/limits/${id}`)
};

const Pages = {
	get: slug =>
		requests.public().get(`/cms/pages/${slug}`)
};

const Content = {
	get: key =>
		requests.public().get(`/cms/contents/${key}`)
};

const User = {
	signIn: (principal, secret) => {
		const data = new FormData();

		data.append('username', principal);
		data.append('password', secret);

		return requests.member().post('/login', data);
	},
	forgot: (authName, language) =>
		requests.public().get(`/forgot-password/${authName}/${language}`),
	forgotReset: data =>
		requests.public().post('/forgot-password/reset', data),
	signOut: () => {},
	me: () => 
		requests.member().get('/api/self'),
	suggest: data =>
		requests.public().post('/partner-suggestion', data),
	companyCode: code =>
		requests.public().get(`/companies/${code}`),
	countries: () =>
		requests.public().get('/legacy/countries'),
	states: countryId =>
		requests.public().get(`/legacy/states/${countryId}`),
	cities: stateId =>
		requests.public().get(`/legacy/cities/${stateId}`),
	levels: () =>
		requests.public().get('/legacy/levels'),
	subscriptions: () =>
		requests.public().get('/legacy/subscriptions'),
	register: data =>
		requests.public().post('/register', data),
	registerPayment: (orderId, data) =>
		requests.public().post(`/register/payment/${orderId}`, data),
	// v2 //
	registerPaymentV2Setup: (orderId, data) =>
		requests.public().post(`/register/payment/v2/setup/${orderId}`, data),
	registerPaymentV2Process: (orderId, data) =>
		requests.public().post(`/register/payment/v2/process/${orderId}`, data),
	registerPaymentV2Complete: authenticationTransactionId =>
		requests.public().post(`/register/payment/v2/process/${authenticationTransactionId}`),
	// --- //
	renew: () =>
		requests.member().post('/api/renew'),
	upgrade: data =>
		requests.member().post('/api/upgrade', data),
	membershipPayment: (orderId, data) =>
		requests.member().post(`/api/membership/payment/${orderId}`, data),
	// v2 //
	membershipPaymentV2Setup: (orderId, data) =>
		requests.member().post(`/api/membership/payment/v2/setup/${orderId}`, data),
	membershipPaymentV2Process: (orderId, data) =>
		requests.member().post(`/api/membership/payment/v2/process/${orderId}`, data),
	membershipPaymentV2Complete: authenticationTransactionId =>
		requests.member().post(`/api/membership/payment/v2/complete/${authenticationTransactionId}`),
	// --- //
	password: data =>
		requests.member().put('/api/update-password', data),
	update: data =>
		requests.member().put('/api/update', data),
	updateV2: data =>
		requests.member().put('/api/v2/update', data),
	orders: (page = 0, limit = Config.RESULTS_PER_PAGE) =>
		requests.member().get(`/api/orders?limit=${limit}&offset=${page * limit}`),
	ordersArchived: (page = 0, limit = Config.RESULTS_PER_PAGE) =>
		requests.member().get(`/api/orders-history?limit=${limit}&offset=${page * limit}`),
	order: orderId =>
		requests.member().get(`/api/orders/${orderId}`),
	orderPDF: (locale, idOrder, idVoucher) =>
		requests.noScope().get(`/vouchers/pdf/${locale}/${idOrder}/${idVoucher}`, null, {
			responseType: 'blob'
		}),
	emailCheck: email =>
		requests.public().get(`/members/email/${email}`),
	mobileCheck: mobile =>
		requests.public().get(`/members/mobile/${mobile}`),
	emiratesIdCheck: emiratesId => 
		requests.public().get(`/members/emiratesId/${emiratesId}`),
	lastTransaction: () =>
		requests.member().get('/api/payment/lastTransaction'),
	persistFavoriteCategories: data =>
		requests.member().post('/api/categories', data),
	submitInsurance: data => 
		requests.member().post('/api/insurance-form', data),
	submitLeaseToOwn: data => 
		requests.member().post('/api/lease-to-own-form', data),
	locations: () =>
		requests.member().get('/api/shipping/locations'),
	addLocation: data => 
		requests.member().post('/api/shipping/location', data),
	defaultLocation: id =>
		requests.member().put(`/api/shipping/location/${id}`),
	deleteLocation: id =>
		requests.member().delete(`/api/shipping/location/${id}`),
	ssoTokenRH: () =>
		requests.member().get('/api/rh-sso'),
	extraOffersSaved: () =>
		requests.member().get('/api/offers-extra/saved'),
	extraOffersTransactions: (page = 0, limit = Config.RESULTS_PER_PAGE) =>
		requests.member().get(`/api/offer-extra/transactions?limit=${limit}&offset=${page * limit}`),
	initiateUAEPassLinking: redirectUri =>
		requests.member().post('/api/uae-pass/associate', { redirectUri }),
	/**
	 * @deprecated
	 */
	unlinkUAEPASS: () =>
		requests.member().put('/api/uae-pass/unlink'),
	initiateUAEPassLogin: redirectUri => {
		const data = new FormData();

		data.append('uaePassRedirectUri', redirectUri);

		return requests.uaepass().post('/login', data);
	},	
	switchToLinkedMemberContext: id =>
		requests.member().post('/uaepass-select', { memberId: id }),
	unlinkMembership: (number, code) =>
		requests.public().get(`/uae-pass/unlink/${number}/${code}`),
};

const SMS = {
	send: data =>
		requests.public().post('/sms-otp', data),
	validate: (id, code) =>
		requests.public().get(`/sms-otp/${id}/${code}`)
};

const Email = {
	send: data =>
		requests.public().post('/email-otp', data),
	validate: (id, code) =>
		requests.public().get(`/email-otp/${id}/${code}`)
};

const Search = {
	search: data =>
		rootStore.userStore.authenticated ? requests.member().get('/api/search', data) : requests.public().get('/search', data),
	quickSearch: data =>
		rootStore.userStore.authenticated ? requests.member().get('/api/quick-search', data) : requests.public().get('/quick-search', data),
	nearme: data =>
		requests.public().get('/hamad/nearme', data),
	locations: () =>
		requests.public().get('/location/map')
};

const Cart = {
	checkout: data =>
		requests.member().post('/api/checkout', data),
	payment: (orderId, data) =>
		requests.member().post(`/api/payment/process/${orderId}`, data),
	// v2 //
	paymentV2Setup: (orderId, data) => 
		requests.member().post(`/api/payment/v2/setup/${orderId}`, data),
	paymentV2Process: (orderId, data) =>
		requests.member().post(`/api/payment/v2/process/${orderId}`, data),
	paymentV2Complete: authenticationTransactionId =>
		requests.member().post(`/api/payment/v2/complete/${authenticationTransactionId}`),
	// --- //
	validate: data =>
		requests.public().post('/cart/validate', data)
};

const Uploads = {
	doUpload: (file, onProgress = null) => {
		const data = new FormData();

		data.append('file', file);

		return requests.member().post('/api/upload', data, true, onProgress);
	}
};

const Cars = {
	list: (page = 0, limit = Config.RESULTS_PER_PAGE) =>
		requests.public().get(`/cars?limit=${limit}&offset=${page * limit}`),
	get: slug =>
		requests.public().get(`/cars/${slug}`)
};

const Estore = {
	search: data =>
		requests.public().get('/store/products/search', data),
	get: (slug, locale) => 
		requests.public().get(`/store/products/item/${slug}?language=${locale}`),
	categories: () =>
		requests.public().get('/store/categories'),
	subCategories: id => 
		requests.public().get(`/store/subcategories/category/${id}`),
	stock: (id, qty) =>
		requests.public().get(`/store/products/stock/${id}/${qty}`)
};

const PrepaidCard = {
	infoBalance: () =>
		requests.member().get('/api/prepaid/service/card/info-and-balance'),
	info: () =>
		requests.member().get('/api/prepaid/service/card/info'),
	balance: () =>
		requests.member().get('/api/prepaid/service/balances'),
	transactions: data =>
		requests.member().post('/api/prepaid/service/transactions', data),
	transfer: data =>
		requests.member().put('/api/prepaid/service/wallet/transfer', data),
	status: data =>
		requests.member().put('/api/prepaid/service/card/status', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		}),
	OTPRequest: () =>
		requests.member().get('/api/prepaid/otp-request'),
	OTPValidate: code =>
		requests.member().post('/api/prepaid/otp-key', code, false, null, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
};

const Payment = {
	getPaymentRequest: id =>
		requests.public().get(`/payment-request/${id}`),
	makePayment: (id, data) =>
		requests.public().post(`/payment-request/${id}`, data)
};

const UsedCars = {
	list: (page = 0, limit = Config.RESULTS_PER_PAGE) =>
		requests.public().get(`/used-cars?limit=${limit}&offset=${page * limit}`),
	get: slug =>
		requests.public().get(`/used-cars/${slug}`)
};

const Fazaa = {
	cancelMembership: (data, ssoToken) => 
		requests.fazaa().post('/member/cancel-account', data, false, null, {
			headers: {
				'Authorization': ssoToken
			}
		})
};

export default {
	Common,
	Home,
	Categories,
	Services,
	Offers,
	Vouchers,
	Pages,
	Content,
	User,
	SMS,
	Email,
	Search,
	Cart,
	Uploads,
	Cars,
	Estore,
	PrepaidCard,
	Payment,
	UsedCars,
	Fazaa
};
