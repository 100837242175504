import { env } from '../env';
import * as Sentry from '@sentry/browser';
import { decorate, observable, action, runInAction, computed, reaction } from 'mobx';
import FazaaAPI from '../api/FazaaAPI';
import Config from '../config/Config';
import moment from 'moment-timezone';
import hash from 'object-hash';
import { union, without } from 'lodash';
import 'whatwg-fetch'; 
import { reportExceptionToSentry, generateRandomString } from '../utils/Utils';

moment.tz.setDefault('Asia/Dubai');

//const VALID_EMAIL = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
const VALID_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const VALID_MOBILE_NO_SPACE = /^\+9715[024568][0-9]{7}$/;
// const VALID_UAE_ID = /^784-[0-9]{4}-[0-9]{7}-[0-9]{1}$/;
const VALID_EMIRATESID = /^784-[0-9]{4}-[0-9]{7}-[0-9]{1,2}$/;

class UserStore {

	authenticated = false;
	theme = 'gold';
	me = undefined;
	position = null;
	positionFromIP = false;
	countries = [];
	subscriptions = [];
	levels = [];

	// Sign in & Sign up
	principal = '';
	secret = '';

	// Password reset
	passwordResetToken = '';

	// Sign up & Profile
	registrationOpen = false;
	verifyCompanyEmailOpen = false;
	personalInformationOpen = false;
	smsVerificationOpen = false;
	additionalInformationOpen = false;
	plansPaymentOpen = false;

	companyCode = '';
	companyName = '';
	companyEmail = '';
	companyEmailVerified = false;
	companyEmailVerificationId = '';
	companyCodeId = null;
	lastCheckedCompanyCode = null;

	email = '';
	mobile = '';
	mobileVerified = false;
	mobileVerificationId = '';
	language = '';
	emiratesId = '';
	emiratesIdExpiry = '';
	dob = '';
	secretConfirm = '';

	smsCode = '';
	smsResendInterval = null;
	smsResendTimer = 0;

	emailCode = '';
	emailResendInterval = null;
	emailResendTimer = 0;

	employeeNo = '';
	gender = '';
	nameAr = '';
	nameEn = '';
	personalFunds = '';
	salary = '';
	nationality = '';
	nameFazaa = '';

	emirate = '';

	subscription = '';
	level = '';

	firstName = '';
	lastName = '';
	address = '';
	postalCode = '00000';
	city = '';
	country = '';

	ccName = '';
	ccNumber = '';
	ccExpiry = '';
	ccCvv = '';
	terms = false;
	termsRefunds = false;

	// Suggest a partner
	businessName = '';
	businessEmail = '';
	businessPhone = '';

	// Change password
	oldSecret = '';

	// Insurance
	uploads = {};

	loading = false;
	switchingContext = false;
	// Used to indicate if UAE PASS operations are underway
	// in order to prevent calling certain APIs if user data are not fully ready
	// TODO Do we need this, first user is already logged in as per new change
	uaePassFlowInProgress = false;
	uaePassManualLinkEligible = false;
	uaePassError = '';
	error = '';
	success = '';
	errors = [];

	threeDSSetupInProgress = false;
	threeDSSetupComplete = false;

	orderId = null;

	redirectTo = '';
	redirectUrl = '';
	redirectRequiresProfile = false;

	sessionTimeoutWarningVisible = false;
	favoriteCategoriesVisible = false;

	favoriteCategories = [];

	// Shipping locations
	shippingLocations = {
		locations: [],
		loading: false,
		working: false,
		fullName: '',
		address: '',
		mobile: '',
		city: ''
	};

	// Chat
	chat = {
		accountNumber: '',
		name: '',
		email: '',
		question: '',
		transcript: false
	};

	constructor(rootStore) {
		this.rootStore = rootStore;

		const token = window.injectedToken || localStorage.getItem('token');
		const prepaidToken = sessionStorage.getItem('prepaidToken');

		if (!!token) {
			this.self(true).then(result => {
				if (result) {
					Sentry.configureScope((scope) => {
						scope.setUser({
							id: this.me.id,
							email: this.me.email,
							extra: {
								memberId: this.me.memberId,
								membershipNumber: this.me.membershipNumber
							}
						});
					});

					this.setAuthenticated(true);

					if (!!prepaidToken) {
						this.rootStore.prepaidCardStore.setSecure(true);
					}

					if (this.redirectUrl && (!this.redirectRequiresProfile || (this.redirectRequiresProfile && this.isProfileComplete))) {
						this.ssoTokenRH().then(token => {
							if (token) {
								window.location = this.redirectUrl + token;
							} else {
								runInAction(() => {
									this.redirectUrl = '';
									this.redirectRequiresProfile = false;
								});
							}
						});
					}
				} else {
					this.signOut();
				}

				this.rootStore.commonStore.setUserReady(true);
			});
		} else {
			this.rootStore.commonStore.setUserReady(true);
		}

		// TODO Pull out into a function
		navigator.geolocation.watchPosition(position => {
			if (this.position === null || this.position.latitude !== Number(position.coords.latitude.toFixed(3)) || this.position.longitude !== Number(position.coords.longitude.toFixed(3))) {
				this.setPosition({
					latitude: Number(position.coords.latitude.toFixed(3)),
					longitude: Number(position.coords.longitude.toFixed(3))
				});

				this.setPositionFromIP(false);

				this.rootStore.commonStore.setLocationReady(true);

				this.rootStore.searchStore.doNearMe();
			}
		}, error => {
			// Fall back to IP geolocation
			this.doIPGeolocation();
		}, {
			enableHighAccuracy: true
		});

		reaction(
			() => this.registerParamsHash,
			() => this.orderId = null
		);

		/* MOVED TO LOAD HOME!
		reaction(
			() => ({
				latitude: this.position ? Number(this.position.latitude.toFixed(3)) : null,
				longitude: this.position ? Number(this.position.longitude.toFixed(3)): null,
				authenticated: this.authenticated
			}),
			() => this.rootStore.refreshOffers(),
			{
				equals: (a, b) => {
					if (a.latitude !== b.latitude || a.longitude !== b.longitude || a.authenticated !== b.authenticated) return false;

					return true;
				}
			}
		);
		*/
	}

	doIPGeolocation() {
		fetch('http://api.ipstack.com/check?access_key=' + Config.IPSTACK_API_ACCESS_KEY + '&fields=latitude,longitude').then(response => {
			return response.json();
		}).then(data => {
			this.setPosition({
				latitude: Number(data.latitude.toFixed(3)),
				longitude: Number(data.longitude.toFixed(3))
			});

			this.setPositionFromIP(true);

			this.rootStore.commonStore.setLocationReady(true);

			this.rootStore.searchStore.doNearMe();
		}).catch(error => {
			console.log('Unable to get location data from IPStack API: ' + error);

			this.setPosition(null);

			this.rootStore.commonStore.setLocationReady(true);
		});
	}

	setAuthenticated(authenticated) {
		this.authenticated = authenticated;
	}

	setTheme(theme) {
		this.theme = theme;
	}

	setMe(data) {
		this.me = data;
	}

	setPosition(position) {
		this.position = position;
	}

	setPositionFromIP(positionFromIP) {
		this.positionFromIP = positionFromIP;
	}

	setCountries(countries) {
		this.countries = countries;
	}

	setSubscriptions(subscriptions) {
		this.subscriptions = subscriptions;
	}

	setLevels(levels) {
		this.levels = levels;
	}

	setPrincipal(principal) {
		this.principal = principal;
	}

	setSecret(secret) {
		this.secret = secret;
	}

	setPasswordResetToken(token) {
		this.passwordResetToken = token;
	}

	setCompanyCode(companyCode) {
		this.companyEmailVerified = false;
		this.companyCode = companyCode;
	}

	setCompanyName(companyName) {
		this.companyName = companyName;
	}

	setCompanyEmail(companyEmail) {
		this.companyEmailVerified = false;
		this.companyEmail = companyEmail.trim();
	}

	setRegistrationOpen(registrationOpen) {
		this.registrationOpen = registrationOpen;
	}

	setVerifyCompanyEmailOpen(verifyCompanyEmailOpen) {
		this.verifyCompanyEmailOpen = verifyCompanyEmailOpen;
	}

	setPersonalInformationOpen(personalInformationOpen) {
		this.personalInformationOpen = personalInformationOpen;
	}

	setSmsVerificationOpen(smsVerificationOpen) {
		this.smsVerificationOpen = smsVerificationOpen;
	}

	setAdditionalInformationOpen(additionalInformationOpen) {
		this.additionalInformationOpen = additionalInformationOpen;
	}

	setPlansPaymentOpen(plansPaymentOpen) {
		this.plansPaymentOpen = plansPaymentOpen;
	}

	setEmail(email) {
		this.email = email.trim();
	}
	
	setMobile(mobile) {
		this.mobile = mobile;
		this.mobileVerified = false;
	}

	setMobileVerified(mobileVerified) {
		this.mobileVerified = mobileVerified;
	}

	setMobileVerificationId(mobileVerificationId) {
		this.mobileVerificationId = mobileVerificationId;
	}

	setCompanyEmailVerified(companyEmailVerified) {
		this.companyEmailVerified = companyEmailVerified;
	}

	setCompanyEmailVerificationId(companyEmailVerificationId) {
		this.companyEmailVerificationId = companyEmailVerificationId;
	}

	setLanguage(language) {
		this.language = language;
	}

	setEmiratesId(emiratesId) {
		this.emiratesId = emiratesId;
	}

	setEmiratesIdExpiry(emiratesIdExpiry) {
		this.emiratesIdExpiry = emiratesIdExpiry;
	}

	setDob(dob) {
		this.dob = dob;
	}

	setSecretConfirm(secretConfirm) {
		this.secretConfirm = secretConfirm;
	}

	setSmsResendTimer(smsResendTimer) {
		this.smsResendTimer = smsResendTimer;
	}

	setSmsCode(smsCode) {
		this.smsCode = smsCode;
	}

	setEmailResendTimer(emailResendTimer) {
		this.emailResendTimer = emailResendTimer;
	}

	setEmailCode(emailCode) {
		this.emailCode = emailCode;
	}

	setEmployeeNo(employeeNo) {
		this.employeeNo = employeeNo;
	}

	setGender(gender) {
		this.gender = gender;
	}

	setNameAr(nameAr) {
		this.nameAr = nameAr;
	}

	setNameEn(nameEn) {
		this.nameEn = nameEn;
	}

	setPersonalFunds(personalFunds) {
		this.personalFunds = personalFunds;
	}

	setSalary(salary) {
		this.salary = salary;
	}

	setNationality(nationality) {
		this.nationality = nationality;
	}

	setNameFazaa(nameFazaa) {
		this.nameFazaa = nameFazaa;
	}

	setEmirate(emirate) {
		this.emirate = emirate;
	}

	setLevel(level) {
		this.level = level;
	}

	setSubscription(subscription) {
		this.subscription = subscription;
	}

	setFirstName(firstName) {
		this.firstName = firstName;
	}

	setLastName(lastName) {
		this.lastName = lastName;
	}

	setAddress(address) {
		this.address = address;
	}

	setPostalCode(postalCode) {
		this.postalCode = postalCode;
	}

	setCity(city) {
		this.city = city;
	}

	setCountry(country) {
		this.country = country;
	}

	setCcName(ccName) {
		this.ccName = ccName;
	}

	setCcNumber(ccNumber) {
		this.ccNumber = ccNumber;
	}

	setCcExpiry(ccExpiry) {
		this.ccExpiry = ccExpiry;
	}

	setCcCvv(ccCvv) {
		this.ccCvv = ccCvv;
	}

	setTerms(terms) {
		this.terms = terms;
	}

	setTermsRefunds(termsRefunds) {
		this.termsRefunds = termsRefunds;
	}

	setBusinessName(businessName) {
		this.businessName = businessName;
	}

	setBusinessEmail(businessEmail) {
		this.businessEmail = businessEmail;
	}

	setBusinessPhone(businessPhone) {
		this.businessPhone = businessPhone;
	}

	setOldSecret(oldSecret) {
		this.oldSecret = oldSecret;
	}

	setLoading(loading) {
		this.loading = loading;
	}

	setSwitchingContext(switchingContext) {
		this.switchingContext = switchingContext;
	}

	setUaePassFlowInProgress(uaePassFlowInProgress) {
		this.uaePassFlowInProgress = uaePassFlowInProgress;
	}

	setUaePassManualLinkEligible(uaePassManualLinkEligible) {
		this.uaePassManualLinkEligible = uaePassManualLinkEligible;
	}

	setUaePassError(uaePassError) {
		this.uaePassError = uaePassError;
	}

	setError(error) {
		this.error = error;
	}

	setSuccess(success) {
		this.success = success;
	}

	setErrors(errors) {
		this.errors = errors;
	}

	setThreeDSSetupInProgress(threeDSSetupInProgress) {
		this.threeDSSetupInProgress = threeDSSetupInProgress
	}

	setThreeDSSetupComplete(threeDSSetupInComplete) {
		this.threeDSSetupInComplete = threeDSSetupInComplete
	}

	setRedirectTo(redirectTo) {
		this.redirectTo = redirectTo;
	}

	setRedirectUrl(redirectUrl) {
		this.redirectUrl = redirectUrl;
	}

	setRedirectRequiresProfile(redirectRequiresProfile) {
		this.redirectRequiresProfile = redirectRequiresProfile;
	}

	async signIn(linkWithUaePass = false) {
		this.setError(false);
		this.setLoading(true);

		try {
			const signInResponse = await FazaaAPI.User.signIn(this.principal.trim(), this.secret);

			if (signInResponse.status === 200) {
				localStorage.setItem('token', signInResponse.headers.authorization);
				sessionStorage.removeItem('prepaidToken');

				if (!linkWithUaePass) {
					// Prevent redirect away from SignIn component if in UAE PASS flow
					this.setAuthenticated(true);
				}

				this.setPrincipal('');
				this.setSecret('');

				this.rootStore.prepaidCardStore.setSecure(false);

				if (await this.self()) {
					Sentry.configureScope((scope) => {
						scope.setUser({
							id: this.me.id,
							email: this.me.email,
							extra: {
								memberId: this.me.memberId,
								membershipNumber: this.me.membershipNumber
							}
						});
					});

					return true;
				} else {
					this.signOut(linkWithUaePass);

					return false;
				}
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.signin.error.signInFail'));

				return false;
			}
		} catch (e) {
			this.setError(this.rootStore.commonStore.translateMessage('page.signin.error.signInBackendError'));

			reportExceptionToSentry(e, [401]);

			return false;
		} finally {
			this.setLoading(false);
		}
	}

	async forgot() {
		this.setError(false);
		this.setSuccess(false);
		this.setLoading(true);

		try {
			const response = await FazaaAPI.User.forgot(this.principal.trim(), this.rootStore.commonStore.locale);

			if (response.status === 201 || response.status === 200) {
				this.setSuccess(this.rootStore.commonStore.translateMessage('page.forgot.success'));
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.forgot.error.noMembership'));
			}
		} catch (e) {
			this.setError(this.rootStore.commonStore.translateMessage('page.forgot.error.backendError'));

			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}
	}

	async forgotReset() {
		this.setError(false);
		this.setSuccess(false);
		this.setLoading(true);

		try {
			const response = await FazaaAPI.User.forgotReset({
				newPassword: this.secret,
				token: this.passwordResetToken
			});

			if (response.status === 200) {
				this.setSuccess(this.rootStore.commonStore.translateMessage('page.reset.success'));
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.reset.error'));
			}
		} catch (e) {
			this.setError(this.rootStore.commonStore.translateMessage('page.reset.error.backendError'));

			reportExceptionToSentry(e, [404]);
		} finally {
			this.setLoading(false);
		}
	}

	/**
	 * @param {boolean} [linkWithUaePass=false] - Flag set if signOut call comes during UAE PASS linking flow
	 * @param {boolean} [preserveLastError=false] - Flag set if signOut call should preserve last error message
	 */
	async signOut(linkWithUaePass = false, preserveLastError = false) {
		try {
			FazaaAPI.User.signOut();
		} catch (e) {
			reportExceptionToSentry(e);
		} finally {
			this.setUaePassFlowInProgress(false);

			this.rootStore.cleanupSessionData();

			Sentry.configureScope((scope) => {
				scope.setUser(null);
			});

			// Perform UAE PASS logout if needed
			const uaePassFlowDataJson = localStorage.getItem('uaePassFlowData');

			if (uaePassFlowDataJson) {
				const uaePassFlowData = JSON.parse(uaePassFlowDataJson);

				if (uaePassFlowData && uaePassFlowData.status && !linkWithUaePass) {
					this.doUAEPASSLogout();

					return;
				}
			}

			if (preserveLastError) {
				this.doUAEPASSLogout(preserveLastError);

				return;
			}
		}
	}

	/**
	 * 
	 * @param {boolean} [preserveLastError=false] - Preserve last error message in local storage
	 */
	doUAEPASSLogout(preserveLastError = false) {
		const redirectUrl = new URL(Config.UAE_PASS_ENDPOINT_LOGOUT);

		redirectUrl.searchParams.set('redirect_uri', (!env.REACT_APP_NODE_ENV ? 'http://localhost:3000' : Config.UPLOADS_ENDPOINT) + '/account/signin');

		localStorage.setItem('uaePassFlowData', JSON.stringify({
			status: false,
			lastError: preserveLastError ? this.uaePassError : undefined
		}));

		window.location = redirectUrl.toString();
	}

	async self(suppress = false) {
		this.setLoading(true);

		try {
			const response = await FazaaAPI.User.me();

			// We have authorization code bude the user did not select any user during UAE Pass login
			// just pretend we are logged out...
			if (response.data.info && response.data.members) {
				return false;
			} else {
				this.setMe(response.data);
			}

			// TODO Why is this sometimes returning empty?
			if (response.data.membershipLevel && response.data.membershipLevel.levelName) {
				this.setTheme(response.data.membershipLevel.levelName.split(' ')[0].toLowerCase());
			}

			return true;
		} catch (e) {
			if (!suppress) {
				this.setError('An error occured while getting user data.');
			}

			reportExceptionToSentry(e, [401]);

			return false;
		} finally {
			this.setLoading(false);
		}
	}

	async suggestPartner() {
		this.setError(false);
		this.setSuccess(false);
		this.setLoading(true);

		try {
			const params = {
				businessName: this.businessName,
				businessEmail: this.businessEmail,
				businessPhone: this.businessPhone
			};

			const response = await FazaaAPI.User.suggest(params);

			if (response.status === 201) {
				if (this.authenticated) {
					this.setSuccess(this.rootStore.commonStore.translateMessage('page.partner.success.suggestPartner'));
				} else {
					this.setSuccess(this.rootStore.commonStore.translateMessage('page.partner.success.becomePartner'));
				}
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.partner.error.checkFields'));
			}
		} catch (e) {
			if (this.authenticated) {
				this.setError(this.rootStore.commonStore.translateMessage('page.partner.error.backendError.suggestPartner'));
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.partner.error.backendError.becomePartner'));
			}

			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}
	}

	async checkCompanyCode() {
		if (!this.companyCode) {
			return false;
		}

		try {
			const response = await FazaaAPI.User.companyCode(this.companyCode);

			if (response.status === 200) {
				runInAction(() => {
					this.lastCheckedCompanyCode = response.data;
				});

				return response.data;
			}
		} catch (e) {
			reportExceptionToSentry(e, [404]);
		}

		return false;
	}

	async checkEmail(update = false) {
		if (this.email) {
			if (update && this.email.toLowerCase() === this.me.email.toLowerCase()) {
				return true;
			}

			try {
				this.setLoading(true);

				await FazaaAPI.User.emailCheck(this.email);

				return false;
			} catch (e) {
				if (e && e.response && e.response.status) {
					if (e.response.status === 302) return false;
					if (e.response.status === 404) return true;
				}

				reportExceptionToSentry(e);

				return false;
			} finally {
				this.setLoading(false);
			}
		}
	}

	async checkMobile(update = false) {
		if (this.mobile) {
			const mobileToCheck = this.mobile.replace(new RegExp(' ', 'g'), '').replace('+', '');

			if (update && mobileToCheck === this.me.mobile.replace(new RegExp(' ', 'g'), '').replace('+', '')) {
				return true;
			}

			try {
				this.setLoading(true);

				await FazaaAPI.User.mobileCheck(mobileToCheck);
			} catch (e) {
				if (e && e.response && e.response.status) {
					if (e.response.status === 302) return false;
					if (e.response.status === 404) return true;
				}

				reportExceptionToSentry(e);

				return false;
			} finally {
				this.setLoading(false);
			}
		}
	}

	async checkEmiratesId(update = false) {
		if (this.emiratesId) {
			if (update && this.emiratesId === this.me.emiratesId) {
				return true;
			}

			try {
				this.setLoading(true);

				await FazaaAPI.User.emiratesIdCheck(this.emiratesId);
			} catch (e) {
				if (e && e.response && e.response.status) {
					if (e.response.status === 302) return false;
					if (e.response.status === 404) return true;
				}

				reportExceptionToSentry(e);

				return false;
			} finally {
				this.setLoading(false);
			}
		}
	}

	async loadCountries() {
		if (this.countries.length > 0) {
			return true;
		}

		try {
			this.setLoading(true);

			const response = await FazaaAPI.User.countries();

			this.setCountries(response.data.filter(d => d.id !== 110).sort((a, b) => {
				return a.countryName.localeCompare(b.countryName);
			}));

			return true;
		} catch (e) {
			reportExceptionToSentry(e);

			return false;
		} finally {
			this.setLoading(false);
		}
	}

	async loadSubscriptions() {
		if (this.subscriptions > 0) {
			return true;
		}

		this.setLoading(true);

		try {
			const response = await FazaaAPI.User.subscriptions();

			this.setSubscriptions(response.data);

			return true;
		} catch (e) {
			reportExceptionToSentry(e);

			return false;
		} finally {
			this.setLoading(false);
		}
	}

	async loadLevels() {
		if (this.levels > 0) {
			return true;
		}

		this.setLoading(true);

		try {
			const response = await FazaaAPI.User.levels();

			this.setLevels(response.data);

			return true;
		} catch (e) {
			reportExceptionToSentry(e);

			return false;
		} finally {
			this.setLoading(false);
		}
	}

	resetRegistrationFields() {
		runInAction(() => {
			this.orderId = null;

			this.error = '';
			this.success = '';
			this.errors = [];

			this.registrationOpen = false;
			this.verifyCompanyEmailOpen = false;
			this.personalInformationOpen = false;
			this.SmsVerificationOpen = false;
			this.additionalInformationOpen = false;
			this.plansPaymentOpen = false;

			this.companyCode = '';
			this.companyName = '';
			this.companyEmail = '';
			this.companyEmailVerified = false;
			this.companyEmailVerificationId = '';
			this.email = '';
			this.mobile = '';
			this.mobileVerified = false;
			this.mobileVerificationId = '';
			this.language = '';
			this.emiratesId = '';
			this.emiratesIdExpiry = '';
			this.dob = '';
			this.secret = '';
			this.secretConfirm = '';
			this.smsCode = '';

			this.employeeNo = '';
			this.gender = '';
			this.nameAr = '';
			this.nameEn = '';
			this.personalFunds = '';
			this.salary = '';
			this.nationality = '';
			this.nameFazaa = '';

			this.emirate = '';

			this.level = 3;
			this.subscription = '';

			this.firstName = '';
			this.lastName = '';
			this.address = '';
			this.postalCode = '00000';
			this.city = '';
			this.country = '';

			this.ccName = '';
			this.ccNumber = '';
			this.ccExpiry = '';
			this.ccCvv = '';
			this.terms = false;
			this.termsRefunds = false;
		});
	}

	async sendSms() {
		this.setSmsCode('');

		try {
			const selectedLanguage = this.rootStore.commonStore.languages.find(l => l.id === this.language);
			const response = await FazaaAPI.SMS.send({
				mobile: this.mobile.replace(new RegExp(' ', 'g'), ''),
				languageCode: selectedLanguage ? selectedLanguage.languageCode : 'en'
			});

			clearInterval(this.smsResendInterval);

			this.setSmsResendTimer(Config.SMS_VERIFICATION_TIMEOUT_SECONDS);

			this.smsResendInterval = setInterval(() => {
				if (this.smsResendTimer > 0) {
					this.setSmsResendTimer(this.smsResendTimer - 1);
				} else {
					clearInterval(this.smsResendInterval);
				}
			}, 1000);

			if (response.status === 201) {
				this.setMobileVerificationId(response.headers.location);

				return true;
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.smsNotSent'));

				return false;
			}
		} catch (e) {
			this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.smsNotSentBackendError'));

			reportExceptionToSentry(e);

			return false;
		}
	}

	async verifySms() {
		if (this.mobileVerificationId && this.smsCode) {
			try {
				this.setLoading(true);

				const response = await FazaaAPI.SMS.validate(this.mobileVerificationId, this.smsCode);

				if (response.data) {
					this.setMobileVerified(true);

					return true;
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.smsVerificationFail'));

					return false;
				}
			} catch (e) {
				this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.smsVerificationBackenderror'));

				reportExceptionToSentry(e);

				return false;
			} finally {
				this.setLoading(false);
			}
		} else {
			return false;
		}
	}

	async sendCompanyEmail() {
		this.setEmailCode('');

		try {
			const selectedLanguage = this.rootStore.commonStore.languages.find(l => l.id === this.language);
			const response = await FazaaAPI.Email.send({
				email: this.companyEmail.trim(),
				companyId: this.companyCodeId,
				languageCode: selectedLanguage ? selectedLanguage.languageCode : 'en'
			});

			clearInterval(this.emailResendInterval);

			this.setEmailResendTimer(Config.EMAIL_VERIFICATION_TIMEOUT_SECONDS);

			this.emailResendInterval = setInterval(() => {
				if (this.emailResendTimer > 0) {
					this.setEmailResendTimer(this.emailResendTimer - 1);
				} else {
					clearInterval(this.emailResendInterval);
				}
			}, 1000);

			if (response.status === 201) {
				this.setCompanyEmailVerificationId(response.headers.location);

				return true;
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.emailNotSent'));

				return false;
			}
		} catch (e) {
			if (e && e.response && e.response.status && e.response.status === 417) {
				if (e.response.code === 'M009') {
					this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.emailNotSentAccessDenied'));
				} else if (e.response.code === 'M011') {
					this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.emailNotSentAlreadyUsed'));
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.emailNotSentWrongDomain'));
				}
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.emailNotSentBackendError'));

				reportExceptionToSentry(e);
			}

			return false;
		}
	}

	async verifyCompanyEmail() {
		if (this.companyEmailVerificationId && this.emailCode) {
			try {
				this.setLoading(true);

				const response = await FazaaAPI.Email.validate(this.companyEmailVerificationId, this.emailCode);

				if (response.data) {
					this.setCompanyEmailVerified(true);

					return true;
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.emailVerificationFail'));

					return false;
				}
			} catch (e) {
				this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.emailVerificationBackenderror'));

				reportExceptionToSentry(e, [404]);

				return false;
			} finally {
				this.setLoading(false);
			}
		} else {
			return false;
		}
	}

	// --

	async registerSetup() {
		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);
		this.setThreeDSSetupInProgress(true);

		try {
			if (!this.orderId) {
				const params = {
					fullNameEn: this.nameEn,
					fullNameAr: this.nameAr ? this.nameAr : this.nameEn,
					email: this.email.trim().toLowerCase(),
					gender: this.gender === '1' ? 'M' : 'F',
					password: this.secret,
					mobile: this.mobile.replace(new RegExp(' ', 'g'), ''),
					preferredLanguage: this.language,
					emiratesIdNum: this.emiratesId,
					emiratesIdExpiry: moment(this.emiratesIdExpiry, 'DD-MM-YYYY').format('YYYY-MM-DD'),
					dateOfBirth: moment(this.dob, 'DD-MM-YYYY').format('YYYY-MM-DD'),
					employeeNumber: this.employeeNo ? this.employeeNo : '',
					nationalityId: parseInt(this.nationality),
					emirateId: parseInt(this.emirate),
					nameOnCard: this.nameFazaa.trim(),
					membershipLevel: parseInt(this.level),
					sourceOfIncome: parseInt(this.personalFunds),
					salaryLevel: parseInt(this.salary),
					companyCode: this.companyCodeId,
					otpId: this.companyEmailVerificationId ? this.companyEmailVerificationId : null
				};

				const response = await FazaaAPI.User.register(params);

				if (response.status === 201) {
					this.orderId = response.headers.location;
				}
			}

			if (this.orderId) {
				if (this.level !== 4) {
					const setupResponse = await FazaaAPI.User.registerPaymentV2Setup(this.orderId, {
						creditCardInfo: {
							cardNumber: this.ccNumber.replace(new RegExp(' ', 'g'), ''),
							expMonth: this.ccExpiry.split(' / ')[0],
							expYear: this.ccExpiry.split(' / ')[1],
							cvv: this.ccCvv
						},
						billingInformation: {
							firstName: this.firstName,
							lastName: this.lastName,
							address: this.address,
							postalCode: this.postalCode,
							city: this.city,
							country: this.country,
							email: this.email
						}
					});

					return setupResponse.data;
				} else {
					this.setSuccess(true);
				}
			}
		} catch (e) {
			// TODO Add 417 handling
			if (e && e.response && e.response.status === 409) {
				if (e.response.data && e.response.data.constraint) {
					if (e.response.data.constraint === 'members_email_key') {
						this.addError('email', this.rootStore.commonStore.translateMessage('sql.error.members_email_key'));
					}

					if (e.response.data.constraint === 'members_emirates_id_num_key') {
						this.addError('emiratesId', this.rootStore.commonStore.translateMessage('sql.error.members_emirates_id_num_key'));
					}

					if (e.response.data.constraint === 'members_mobile_key') {
						this.addError('mobile', this.rootStore.commonStore.translateMessage('sql.error.members_mobile_key'));
					}
				}

				this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.checkFields'));
			} else {
				if (e && e.response && e.response.data) {
					this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.backendError', {
						reason: e.response.data.localizedMessage || e.response.data.message
					}));
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.signupErrorNonDescript'));
				}
			}

			reportExceptionToSentry(e, [417, 422]);
		} finally {
			this.setLoading(false);
		}

		return false;
	}

	async register(referenceId = null) {
		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);

		try {
			// We should not need this at this point
			if (!this.orderId) {
				const params = {
					fullNameEn: this.nameEn,
					fullNameAr: this.nameAr ? this.nameAr : this.nameEn,
					email: this.email.trim().toLowerCase(),
					gender: this.gender === '1' ? 'M' : 'F',
					password: this.secret,
					mobile: this.mobile.replace(new RegExp(' ', 'g'), ''),
					preferredLanguage: this.language,
					emiratesIdNum: this.emiratesId,
					emiratesIdExpiry: moment(this.emiratesIdExpiry, 'DD-MM-YYYY').format('YYYY-MM-DD'),
					dateOfBirth: moment(this.dob, 'DD-MM-YYYY').format('YYYY-MM-DD'),
					employeeNumber: this.employeeNo ? this.employeeNo : '',
					nationalityId: parseInt(this.nationality),
					emirateId: parseInt(this.emirate),
					nameOnCard: this.nameFazaa.trim(),
					membershipLevel: parseInt(this.level),
					sourceOfIncome: parseInt(this.personalFunds),
					salaryLevel: parseInt(this.salary),
					companyCode: this.companyCodeId,
					otpId: this.companyEmailVerificationId ? this.companyEmailVerificationId : null
				};

				const response = await FazaaAPI.User.register(params);

				if (response.status === 201) {
					this.orderId = response.headers.location;
				}
			}

			if (this.orderId) {
				const paymentResponse = await FazaaAPI.User.registerPaymentV2Process(this.orderId, {
					creditCardInfo: {
						cardNumber: this.ccNumber.replace(new RegExp(' ', 'g'), ''),
						expMonth: this.ccExpiry.split(' / ')[0],
						expYear: this.ccExpiry.split(' / ')[1],
						cvv: this.ccCvv
					},
					billingInformation: {
						firstName: this.firstName,
						lastName: this.lastName,
						address: this.address,
						postalCode: this.postalCode,
						city: this.city,
						country: this.country,
						email: this.email
					},
					referenceId,
					returnUrl: (!env.REACT_APP_NODE_ENV ? 'http://localhost:3000' : Config.UPLOADS_ENDPOINT) + '/account/signup'
				});

				if (paymentResponse.status === 201) {
					if (paymentResponse.data.veresEnrolled === 'Y' &&
						 (paymentResponse.data.paresStatus === 'C' || !paymentResponse.data.paresStatus) &&
						 (paymentResponse.data.stepUpUrl))
					{
						return {
							status: 'CHALLENGE_REQUIRED',
							data: paymentResponse.data
						}
					}

					if (paymentResponse.data.veresEnrolled === 'Y' &&
							paymentResponse.data.paresStatus === 'Y')
					{
						// Payment has been completed as before
						this.setSuccess(true);

						return {
							status: 'FRICTIONLESS',
							data: paymentResponse.data
						}
					}

					if (paymentResponse.data.veresEnrolled === 'Y' &&
							paymentResponse.data.paresStatus === 'A')
					{
						// Payment has been completed as before
						this.setSuccess(true);

						return {
							status: 'FRICTIONLESS_STAND_IN',
							data: paymentResponse.data
						}
					}

					if (paymentResponse.data.veresEnrolled === 'B' ||
							paymentResponse.data.veresEnrolled === 'U')
					{
						// Payment has been completed as before
						this.setSuccess(true);

						return {
							status: 'NO_LIABILITY_SHIFT',
							data: paymentResponse.data
						}
					}

					// Rejections
					this.setSuccess(false);

					if (paymentResponse.data.cardholderMessage) {
						this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.threeDSPaymentRejectedReason', {
							reason: paymentResponse.data.cardholderMessage
						}));
					} else {
						this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.threeDSPaymentRejected'));
					}

					return {
						status: 'REJECTED',
						data: paymentResponse.data
					}
				} else {
					if (paymentResponse.data.message) {
						this.setError(paymentResponse.data.message);
					} else {
						this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.paymentError'));
					}
				}
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.checkFields'));
			}
		} catch (e) {
			// TODO Add 417 handling
			if (e && e.response && e.response.status === 409) {
				if (e.response.data && e.response.data.constraint) {
					if (e.response.data.constraint === 'members_email_key') {
						this.addError('email', this.rootStore.commonStore.translateMessage('sql.error.members_email_key'));
					}

					if (e.response.data.constraint === 'members_emirates_id_num_key') {
						this.addError('emiratesId', this.rootStore.commonStore.translateMessage('sql.error.members_emirates_id_num_key'));
					}

					if (e.response.data.constraint === 'members_mobile_key') {
						this.addError('mobile', this.rootStore.commonStore.translateMessage('sql.error.members_mobile_key'));
					}
				}

				this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.checkFields'));
			} else if (e && e.response && e.response.data) {
				if (e.response.data.code === 'R100') {
					this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.cardAuthDeclined'));
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.backendError', {
						reason: e.response.data.localizedMessage || e.response.data.message
					}));
				}
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.signupErrorNonDescript'));
			}

			reportExceptionToSentry(e, [417, 422]);
		} finally {
			this.setLoading(false);
		}
	}

	async registerComplete(authenticationTransactionId = null) {
		if (!authenticationTransactionId) return false;

		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);
		this.setThreeDSSetupInProgress(true);

		try {
			const paymentResponse = await FazaaAPI.User.registerPaymentV2Complete(authenticationTransactionId);
			
			if (paymentResponse.status === 201) {
				this.setSuccess(true);

				this.self(true);
			} else {
				if (paymentResponse.data.message) {
					this.setError(paymentResponse.data.message);
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.paymentError'));
				}
			}
		} catch (e) {
			// R100 error means card authorization was declined
			if (e && e.response && e.response.data) {
				if (e.response.data.code === 'R100') {
					this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.cardAuthDeclined'));
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.backendError', {
						reason: e.response.data.localizedMessage || e.response.data.message
					}));
				}
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.signup.error.signupErrorNonDescript'));
			}

			reportExceptionToSentry(e, [422]);
		} finally {
			this.setLoading(false);
			this.setThreeDSSetupInProgress(false);
		}
	}

	// -- renew --

	async renewSetup() {
		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);
		this.setThreeDSSetupInProgress(true);

		try {
			if (!this.orderId) {
				const response = await FazaaAPI.User.renew();

				if (response.status === 201) {
					this.orderId = response.headers.location;
				}
			}

			if (this.orderId) {
				const setupResponse = await FazaaAPI.User.membershipPaymentV2Setup(this.orderId, {
					creditCardInfo: {
						cardNumber: this.ccNumber.replace(new RegExp(' ', 'g'), ''),
						expMonth: this.ccExpiry.split(' / ')[0],
						expYear: this.ccExpiry.split(' / ')[1],
						cvv: this.ccCvv
					},
					billingInformation: {
						firstName: this.firstName,
						lastName: this.lastName,
						address: this.address,
						postalCode: this.postalCode,
						city: this.city,
						country: this.country,
						email: this.me.email
					}
				});

				return setupResponse.data;
			}
		} catch (e) {
			if (e && e.response && e.response.data) {
				this.setError(this.rootStore.commonStore.translateMessage('page.renew.error.backendError', {
					reason: e.response.data.localizedMessage || e.response.data.message
				}));
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.renew.error.renewErrorNonDescript'));
			}

			reportExceptionToSentry(e, [422]);
		} finally {
			this.setLoading(false);
		}

		return false;
	}

	async renew(referenceId = null) {
		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);

		try {
			// We should not need this at this point
			if (!this.orderId) {
				const response = await FazaaAPI.User.renew();

				if (response.status === 201) {
					this.orderId = response.headers.location;
				}
			}

			if (this.orderId) {
				const paymentResponse = await FazaaAPI.User.membershipPaymentV2Process(this.orderId, {
					creditCardInfo: {
						cardNumber: this.ccNumber.replace(new RegExp(' ', 'g'), ''),
						expMonth: this.ccExpiry.split(' / ')[0],
						expYear: this.ccExpiry.split(' / ')[1],
						cvv: this.ccCvv
					},
					billingInformation: {
						firstName: this.firstName,
						lastName: this.lastName,
						address: this.address,
						postalCode: this.postalCode,
						city: this.city,
						country: this.country,
						email: this.me.email
					},
					referenceId,
					returnUrl: (!env.REACT_APP_NODE_ENV ? 'http://localhost:3000' : Config.UPLOADS_ENDPOINT) + '/account/renew'
				});

				if (paymentResponse.status === 201) {
					if (paymentResponse.data.veresEnrolled === 'Y' &&
						 (paymentResponse.data.paresStatus === 'C' || !paymentResponse.data.paresStatus) &&
						 (paymentResponse.data.stepUpUrl))
					{
						return {
							status: 'CHALLENGE_REQUIRED',
							data: paymentResponse.data
						}
					}

					if (paymentResponse.data.veresEnrolled === 'Y' &&
							paymentResponse.data.paresStatus === 'Y')
					{
						// Payment has been completed as before
						this.setSuccess(true);

						this.self(true);

						return {
							status: 'FRICTIONLESS',
							data: paymentResponse.data
						}
					}

					if (paymentResponse.data.veresEnrolled === 'Y' &&
							paymentResponse.data.paresStatus === 'A')
					{
						// Payment has been completed as before
						this.setSuccess(true);

						this.self(true);

						return {
							status: 'FRICTIONLESS_STAND_IN',
							data: paymentResponse.data
						}
					}

					if (paymentResponse.data.veresEnrolled === 'B' ||
							paymentResponse.data.veresEnrolled === 'U')
					{
						// Payment has been completed as before
						this.setSuccess(true);

						this.self(true);

						return {
							status: 'NO_LIABILITY_SHIFT',
							data: paymentResponse.data
						}
					}

					// Rejections
					this.setSuccess(false);

					if (paymentResponse.data.cardholderMessage) {
						this.setError(this.rootStore.commonStore.translateMessage('page.renew.error.threeDSPaymentRejectedReason', {
							reason: paymentResponse.data.cardholderMessage
						}));
					} else {
						this.setError(this.rootStore.commonStore.translateMessage('page.renew.error.threeDSPaymentRejected'));
					}

					return {
						status: 'REJECTED',
						data: paymentResponse.data
					}
				} else {
					if (paymentResponse.data.message) {
						this.setError(paymentResponse.data.message);
					} else {
						this.setError(this.rootStore.commonStore.translateMessage('page.renew.error.paymentError'));
					}
				}				
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.renew.error.checkFields'));
			}
		} catch (e) {
			// R100 error means card authorization was declined
			if (e && e.response && e.response.data) {
				if (e.response.data.code === 'R100') {
					this.setError(this.rootStore.commonStore.translateMessage('page.renew.error.cardAuthDeclined'));
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.renew.error.backendError', {
						reason: e.response.data.localizedMessage || e.response.data.message
					}));
				}
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.renew.error.renewErrorNonDescript'));
			}

			reportExceptionToSentry(e, [422]);
		} finally {
			this.setLoading(false);
		}
	}

	async renewComplete(authenticationTransactionId = null) {
		if (!authenticationTransactionId) return false;

		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);
		this.setThreeDSSetupInProgress(true);

		try {
			const paymentResponse = await FazaaAPI.User.membershipPaymentV2Complete(authenticationTransactionId);
			
			if (paymentResponse.status === 201) {
				this.setSuccess(true);

				this.self(true);
			} else {
				if (paymentResponse.data.message) {
					this.setError(paymentResponse.data.message);
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.renew.error.paymentError'));
				}
			}
		} catch (e) {
			// R100 error means card authorization was declined
			if (e && e.response && e.response.data) {
				if (e.response.data.code === 'R100') {
					this.setError(this.rootStore.commonStore.translateMessage('page.renew.error.cardAuthDeclined'));
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.renew.error.backendError', {
						reason: e.response.data.localizedMessage || e.response.data.message
					}));
				}
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.renew.error.renewErrorNonDescript'));
			}

			reportExceptionToSentry(e, [422]);
		} finally {
			this.setLoading(false);
			this.setThreeDSSetupInProgress(false);
		}
	}
	
	// -- upgrade --

	async upgradeSetup() {
		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);
		this.setThreeDSSetupInProgress(true);

		try {
			if (!this.orderId) {
				const response = await FazaaAPI.User.upgrade({
					membershipLevel: this.level,
					nameoncard: this.nameFazaa.trim()
				});

				if (response.status === 201) {
					this.orderId = response.headers.location;
				}
			}

			if (this.orderId) {
				const setupResponse = await FazaaAPI.User.membershipPaymentV2Setup(this.orderId, {
					creditCardInfo: {
						cardNumber: this.ccNumber.replace(new RegExp(' ', 'g'), ''),
						expMonth: this.ccExpiry.split(' / ')[0],
						expYear: this.ccExpiry.split(' / ')[1],
						cvv: this.ccCvv
					},
					billingInformation: {
						firstName: this.firstName,
						lastName: this.lastName,
						address: this.address,
						postalCode: this.postalCode,
						city: this.city,
						country: this.country,
						email: this.me.email
					}
				});

				return setupResponse.data;
			}
		} catch (e) {
			if (e && e.response && e.response.data) {
				this.setError(this.rootStore.commonStore.translateMessage('page.upgrade.error.backendError', {
					reason: e.response.data.localizedMessage || e.response.data.message
				}));
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.upgrade.error.upgradeErrorNonDescript'));
			}

			reportExceptionToSentry(e, [422]);
		} finally {
			this.setLoading(false);
		}

		return false;
	}

	async upgrade(referenceId = null) {
		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);

		try {
			// We should not need this at this point
			if (!this.orderId) {
				const response = await FazaaAPI.User.upgrade({
					membershipLevel: this.level,
					nameoncard: this.nameFazaa.trim()
				});

				if (response.status === 201) {
					this.orderId = response.headers.location;
				}
			}

			if (this.orderId) {
				const paymentResponse = await FazaaAPI.User.membershipPaymentV2Process(this.orderId, {
					creditCardInfo: {
						cardNumber: this.ccNumber.replace(new RegExp(' ', 'g'), ''),
						expMonth: this.ccExpiry.split(' / ')[0],
						expYear: this.ccExpiry.split(' / ')[1],
						cvv: this.ccCvv
					},
					billingInformation: {
						firstName: this.firstName,
						lastName: this.lastName,
						address: this.address,
						postalCode: this.postalCode,
						city: this.city,
						country: this.country,
						email: this.me.email
					},
					referenceId,
					returnUrl: (!env.REACT_APP_NODE_ENV ? 'http://localhost:3000' : Config.UPLOADS_ENDPOINT) + '/account/upgrade'
				});
				
				if (paymentResponse.status === 201) {
					if (paymentResponse.data.veresEnrolled === 'Y' &&
						 (paymentResponse.data.paresStatus === 'C' || !paymentResponse.data.paresStatus) &&
						 (paymentResponse.data.stepUpUrl))
					{
						return {
							status: 'CHALLENGE_REQUIRED',
							data: paymentResponse.data
						}
					}

					if (paymentResponse.data.veresEnrolled === 'Y' &&
							paymentResponse.data.paresStatus === 'Y')
					{
						// Payment has been completed as before
						this.setSuccess(true);

						this.self(true);

						return {
							status: 'FRICTIONLESS',
							data: paymentResponse.data
						}
					}

					if (paymentResponse.data.veresEnrolled === 'Y' &&
							paymentResponse.data.paresStatus === 'A')
					{
						// Payment has been completed as before
						this.setSuccess(true);

						this.self(true);

						return {
							status: 'FRICTIONLESS_STAND_IN',
							data: paymentResponse.data
						}
					}

					if (paymentResponse.data.veresEnrolled === 'B' ||
							paymentResponse.data.veresEnrolled === 'U')
					{
						// Payment has been completed as before
						this.setSuccess(true);

						this.self(true);

						return {
							status: 'NO_LIABILITY_SHIFT',
							data: paymentResponse.data
						}
					}

					// Rejections
					this.setSuccess(false);

					if (paymentResponse.data.cardholderMessage) {
						this.setError(this.rootStore.commonStore.translateMessage('page.upgrade.error.threeDSPaymentRejectedReason', {
							reason: paymentResponse.data.cardholderMessage
						}));
					} else {
						this.setError(this.rootStore.commonStore.translateMessage('page.upgrade.error.threeDSPaymentRejected'));
					}

					return {
						status: 'REJECTED',
						data: paymentResponse.data
					}
				} else {
					if (paymentResponse.data.message) {
						this.setError(paymentResponse.data.message);
					} else {
						this.setError(this.rootStore.commonStore.translateMessage('page.upgrade.error.paymentError'));
					}
				}
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.upgrade.error.checkFields'));
			}
		} catch (e) {
			// R100 error means card authorization was declined
			if (e && e.response && e.response.data) {
				if (e.response.data.code === 'R100') {
					this.setError(this.rootStore.commonStore.translateMessage('page.upgrade.error.cardAuthDeclined'));
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.upgrade.error.backendError', {
						reason: e.response.data.localizedMessage || e.response.data.message
					}));
				}
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.upgrade.error.upgradeErrorNonDescript'));
			}

			reportExceptionToSentry(e, [422]);
		} finally {
			this.setLoading(false);
		}
	}

	async upgradeComplete(authenticationTransactionId = null) {
		if (!authenticationTransactionId) return false;

		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);
		this.setThreeDSSetupInProgress(true);

		try {
			const paymentResponse = await FazaaAPI.User.membershipPaymentV2Complete(authenticationTransactionId);
			
			if (paymentResponse.status === 201) {
				this.setSuccess(true);

				this.self(true);
			} else {
				if (paymentResponse.data.message) {
					this.setError(paymentResponse.data.message);
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.upgrade.error.paymentError'));
				}
			}
		} catch (e) {
			// R100 error means card authorization was declined
			if (e && e.response && e.response.data) {
				if (e.response.data.code === 'R100') {
					this.setError(this.rootStore.commonStore.translateMessage('page.upgrade.error.cardAuthDeclined'));
				} else {
					this.setError(this.rootStore.commonStore.translateMessage('page.upgrade.error.backendError', {
						reason: e.response.data.localizedMessage || e.response.data.message
					}));
				}
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.upgrade.error.upgradeErrorNonDescript'));
			}

			reportExceptionToSentry(e, [422]);
		} finally {
			this.setLoading(false);
			this.setThreeDSSetupInProgress(false);
		}
	}

	// --

	async changePassword() {
		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);

		try {
			const response = await FazaaAPI.User.password({
				oldPassword: this.oldSecret,
				newPassword: this.secret
			});

			if (response.status === 200) {
				this.setSuccess(true);

				runInAction(() => {
					this.me.passwordChangeNeeded = 0;
				});
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.password.error.currentPassword'));
			}
		} catch(e) {
			this.setError(this.rootStore.commonStore.translateMessage('page.password.error.backendError'));

			reportExceptionToSentry(e, [403]);
		} finally {
			this.setLoading(false);
		}
	}

	loadProfileFields() {
		if (this.me) {
			this.email = this.me.email ? this.me.email.toLowerCase() : '';
			this.mobile = this.me.mobile ? this.me.mobile : '';
			this.mobileVerified = true;
			//this.emirate = this.me.emirate ? this.me.emirate.id : '';
			//this.emiratesId = this.me.emiratesIdNum ? this.me.emiratesIdNum : '';
			//this.emiratesIdExpiry = this.me.emiratesIdExpiry ? moment(this.me.emiratesIdExpiry, 'YYYY-MM-DD').format('DD-MM-YYYY') : '';
			//this.dob = this.me.dateOfBirth ? moment(this.me.dateOfBirth, 'YYYY-MM-DD').format('DD-MM-YYYY') : '';
			this.language = this.me.preferredLanguage ? this.me.preferredLanguage.id : '';
			//this.employeeNo = this.me.employeeNumber ? this.me.employeeNumber : '';
			//this.gender = this.me.gender ? (this.me.gender === 'M' ? 1 : 2) : '';
			this.nameAr = this.me.fullNameAr ? this.me.fullNameAr : '';
			this.nameEn = this.me.fullNameEn ? this.me.fullNameEn : '';
			//this.personalFunds = this.me.sourceOfIncome ? this.me.sourceOfIncome : '';
			//this.salary = this.me.salaryLevel ? this.me.salaryLevel : '';
			//this.nationality = this.me.nationality ? this.me.nationality.id : '';
		}
	}

	async updateProfile() {
		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);

		try {
			const params = {
				fullNameEn: this.nameEn,
				fullNameAr: this.nameAr ? this.nameAr : this.nameEn,
				email: this.email.toLowerCase().trim(),
				//gender: this.gender === '1' ? 'M' : 'F',
				mobile: this.mobile.replace(new RegExp(' ', 'g'), ''),
				preferredLanguage: this.language,
				//emiratesIdNum: this.emiratesId,
				//emiratesIdExpiry: moment(this.emiratesIdExpiry, 'DD-MM-YYYY').format('YYYY-MM-DD'),
				//dateOfBirth: moment(this.dob, 'DD-MM-YYYY').format('YYYY-MM-DD'),
				//employeeNumber: this.employeeNo ? this.employeeNo : '',
				//nationalityId: this.nationality,
				//emirateId: this.emirate,
				//sourceOfIncome: parseInt(this.personalFunds),
				//salaryLevel: this.salary,
			};

			const response = await FazaaAPI.User.updateV2(params);

			if (response.status === 200) {
				this.setSuccess(true);

				this.self(true);
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.profile.error.checkFields'));
			}
		} catch (e) {
			if (e && e.response && e.response.status === 409) {
				if (e.response.data && e.response.data.constraint) {
					if (e.response.data.constraint === 'members_email_key') {
						this.addError('email', this.rootStore.commonStore.translateMessage('sql.error.members_email_key'));
					}

					//if (e.response.data.constraint === 'members_emirates_id_num_key') {
					//	this.addError('emiratesId', this.rootStore.commonStore.translateMessage('sql.error.members_emirates_id_num_key'));
					//}

					if (e.response.data.constraint === 'members_mobile_key') {
						this.addError('mobile', this.rootStore.commonStore.translateMessage('sql.error.members_mobile_key'));
					}
				}

				this.setError(this.rootStore.commonStore.translateMessage('page.profile.error.checkFields'));
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.profile.error.backendError'));
			}

			reportExceptionToSentry(e, [401, 409]);
		} finally {
			this.setLoading(false);
		}
	}

	clearErrors() {
		this.uaePassError = false;
		this.error = false;
		this.errors = [];
	}

	addError(field, message) {
		this.errors.push({
			field,
			message
		});
	}

	clearError(field) {
		const index = this.errors.findIndex(error => error.field === field);

		if (index !== -1) {
			this.errors.splice(index, 1);
		}
	}

	get isProfileComplete() {
		if (this.me) {
			if (!this.me.email || !VALID_EMAIL.test(this.me.email)) {
				return false;
			}

			if (!this.me.mobile || !VALID_MOBILE_NO_SPACE.test(this.me.mobile)) {
				return false;
			}

			//if (!this.me.emirate) {
			//	return false;
			//}

			//if (!this.me.emiratesIdNum || !VALID_UAE_ID.test(this.me.emiratesIdNum)) {
			//	return false;
			//}

			//if (!this.me.emiratesIdExpiry || !moment(this.me.emiratesIdExpiry, 'YYYY-MM-DD', true).isValid() || !moment(this.me.emiratesIdExpiry, 'YYYY-MM-DD', true).isAfter()) {
			//	return false;
			//}

			//if (!this.me.dateOfBirth || !moment(this.me.dateOfBirth, 'YYYY-MM-DD', true).isValid() || !moment(this.me.dateOfBirth, 'YYYY-MM-DD', true).isBefore()) {
			//	return false;
			//}

			// Temporarily disabled on 2022-03-20
			//if (!this.me.preferredLanguage) {
			//	return false;
			//}

			/*
			if (!this.me.employeeNumber || this.me.employeeNumber === 'null') {
				return false;
			}
			*/

			//if (!this.me.gender) {
			//	return false;
			//}

			if (!this.me.fullNameAr) {
				return false;
			}

			if (!this.me.fullNameEn) {
				return false;
			}

			//if (!this.me.sourceOfIncome) {
			//	return false;
			//}

			//if (!this.me.salaryLevel) {
			//	return false;
			//}

			//if (!this.me.nationality) {
			//	return false;
			//}

			return true;
		}

		return null;
	}

	get isPasswordChangeNeeded() {
		// Temporarily disabled on 2022-03-20
		//return this.me && this.me.passwordChangeNeeded;
		return false;
	}

	verifyProfile() {
		if (this.me) {
			if (!this.me.email || !VALID_EMAIL.test(this.me.email)) {
				this.addError('email', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.email'));
			}

			if (!this.me.mobile || !VALID_MOBILE_NO_SPACE.test(this.me.mobile)) {
				this.addError('mobile', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.mobile'));
			}

			//if (!this.me.emirate) {
			//	this.addError('emirate', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.emirate'));
			//}

			//if (!this.me.emiratesIdNum || !VALID_UAE_ID.test(this.me.emiratesIdNum)) {
			//	this.addError('emiratesId', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.emiratesId'));
			//}

			//if (!this.me.emiratesIdExpiry || !moment(this.me.emiratesIdExpiry, 'YYYY-MM-DD', true).isValid() || !moment(this.me.emiratesIdExpiry, 'YYYY-MM-DD', true).isAfter()) {
			//	this.addError('emiratesIdExpiry', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.emiratesIdExpiry'));
			//}

			//if (!this.me.dateOfBirth || !moment(this.me.dateOfBirth, 'YYYY-MM-DD', true).isValid() || !moment(this.me.dateOfBirth, 'YYYY-MM-DD', true).isBefore()) {
			//	this.addError('dob', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.dob'));
			//}

			// Temporarily disabled on 2022-03-20
			//if (!this.me.preferredLanguage) {
			//	this.addError('language', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.language'));
			//}

			/*
			if (!this.me.employeeNumber || this.me.employeeNumber === 'null') {
				this.addError('employeeNo', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.employeeNo'));
			}
			*/

			//if (!this.me.gender) {
			//	this.addError('gender', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.gender'));
			//}

			/*
			if (!this.me.fullNameAr) {
				this.addError('nameAr', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.nameAr'));
			}
			*/

			// TODO Maybe require space?
			if (!this.me.fullNameEn) {
				this.addError('nameEn', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.nameEn'));
			}

			//if (!this.me.sourceOfIncome) {
			//	this.addError('personalFunds', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.personalFunds'));
			//}

			//if (!this.me.salaryLevel) {
			//	this.addError('salary', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.salary'));
			//}

			//if (!this.me.nationality) {
			//	this.addError('nationality', this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.nationality'));
			//}

			if (this.errors.length > 0) {
				this.setError(this.rootStore.commonStore.translateMessage('page.profile.error.incomplete.general'));

				return false;
			}

			return true;
		}

		return null;
	}

	get registerParamsHash() {
		return hash({
			fullNameEn: this.nameEn,
			fullNameAr: this.nameAr ? this.nameAr : this.nameEn,
			email: this.email,
			gender: this.gender === '1' ? 'M' : 'F',
			password: this.secret,
			mobile: this.mobile.replace(new RegExp(' ', 'g'), ''),
			preferredLanguage: this.language,
			emiratesIdNum: this.emiratesId,
			emiratesIdExpiry: moment(this.emiratesIdExpiry, 'DD-MM-YYYY').format('YYYY-MM-DD'),
			dateOfBirth: moment(this.dob, 'DD-MM-YYYY').format('YYYY-MM-DD'),
			employeeNumber: this.employeeNo,
			nationalityId: parseInt(this.nationality),
			emirateId: parseInt(this.emirate),
			nameOnCard: this.nameFazaa,
			membershipLevel: parseInt(this.level),
			sourceOfIncome: parseInt(this.personalFunds),
			salaryLevel: parseInt(this.salary),
			companyCode: this.companyCodeId,
			otpId: this.companyEmailVerificationId ? this.companyEmailVerificationId : null
		});
	}

	async getLastTransactionDetails() {
		try {
			const response = await FazaaAPI.User.lastTransaction();

			if (response.status === 200) {
				return response.data;
			}
		} catch (e) {
			reportExceptionToSentry(e, [401, 404]);
		}

		return null;
	}

	showSessionTimeoutWarning() {
		this.sessionTimeoutWarningVisible = true;
	}

	hideSessionTimeoutWarning() {
		this.sessionTimeoutWarningVisible = false;
	}

	get isCategoryOnboardingDone() {
		if (!this.me) return null;

		return this.me && this.me.categories && this.me.categories.length === 3;
	}

	showFavoriteCategories() {
		this.favoriteCategoriesVisible = true;
	}

	hideFavoriteCategories() {
		this.favoriteCategoriesVisible = false;
	}

	loadFavoriteCategories() {
		this.favoriteCategories = this.me && this.me.categories ? [...this.me.categories] : [];
	}

	toggleFavoriteCategory(id) {
		if (this.favoriteCategories.length === 3 && !this.favoriteCategories.includes(id)) {
			return;
		}

		if (this.favoriteCategories.includes(id)) {
			this.favoriteCategories = without(this.favoriteCategories, id);
		} else {
			this.favoriteCategories = union(this.favoriteCategories, [id]);
		}
	}

	async persistFavoriteCategories() {
		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);

		try {
			const params = {
				categories: this.favoriteCategories
			};

			const response = await FazaaAPI.User.persistFavoriteCategories(params);

			if (response.status === 200) {
				this.setSuccess(true);
				this.self(true);
				this.hideFavoriteCategories();

				return true;
			}
		} catch (e) {
			if (e && e.response && e.response.status === 409) {
				this.setError(this.rootStore.commonStore.translateMessage('page.favoriteCategories.error.checkFields'));
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.favoriteCategories.error.backendError'));
			}

			reportExceptionToSentry(e, [401, 409]);
		} finally {
			this.setLoading(false);
		}
	}

	clearUploads() {
		this.uploads = {};
	}

	async addUpload(name, file, originalFileName = null) {
		runInAction(() => {
			this.uploads[name] = {
				progress: 0,
				uri: '',
				name: ''
			};
		});

		try {
			const response = await FazaaAPI.Uploads.doUpload(file, progressEvent => {
				runInAction(() => {
					this.uploads[name] = {
						...this.uploads[name],
						progress: Math.round((progressEvent.loaded * 100) / progressEvent.total)
					}
				});
			});

			runInAction(() => {
				this.uploads[name] = {
					name: response.data.name,
					uri: response.data.uri,
					progress: 100,
					originalFileName
				};
			});
		} catch (e) {
			this.setError(this.rootStore.commonStore.translateMessage('page.insurance.error.upload', { 
				reason: e.message
			}));
		}
	}

	async submitInsurance() {
		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);

		try {
			const params = {
        		carOwnershipFront: this.uploads.carOwnershipFront.name,
        		carOwnershipBack: this.uploads.carOwnershipBack.name,
        		drivingLicenseFront: this.uploads.drivingLicenseFront.name,
        		drivingLicenseBack: this.uploads.drivingLicenseBack.name
			};

			const response = await FazaaAPI.User.submitInsurance(params);

			if (response.status === 200) {
				this.setSuccess(true);

				return true;
			}
		} catch (e) {
			if (e && e.response && e.response.status === 409) {
				this.setError(this.rootStore.commonStore.translateMessage('page.insurance.error.checkFields'));
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.insurance.error.backendError'));
			}

			reportExceptionToSentry(e, [401, 409]);
		} finally {
			this.setLoading(false);
		}
	}

	async submitLeaseToOwn(carName) {
		this.setError(false);
		this.setSuccess(false);
		this.setErrors([]);
		this.setLoading(true);

		try {
			const params = {
				emiratesId: this.uploads.emiratesId.name,
				emiratesIdBack: this.uploads.emiratesIdBack.name,
				drivingLicense: this.uploads.drivingLicense.name,
				drivingLicenseBack: this.uploads.drivingLicenseBack.name,
        		salaryCertificate: this.uploads.salaryCertificate.name,
				bankStatement: this.uploads.bankStatement.name,
				carName: carName
			};

			const response = await FazaaAPI.User.submitLeaseToOwn(params);

			if (response.status === 200) {
				this.setSuccess(true);

				return true;
			}
		} catch (e) {
			if (e && e.response && e.response.status === 409) {
				this.setError(this.rootStore.commonStore.translateMessage('page.leaseToOwn.error.checkFields'));
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.leaseToOwn.error.backendError'));
			}

			reportExceptionToSentry(e, [401, 409]);
		} finally {
			this.setLoading(false);
		}
	}

	clearLocationForm() {
		this.shippingLocations.fullName = '';
		this.shippingLocations.address = '';
		this.shippingLocations.city = '';
		this.shippingLocations.mobile = '';
	}

	async loadLocations() {
		this.shippingLocations.loading = true;

		try {
			const response = await FazaaAPI.User.locations();

			if (response.status === 200) {
				runInAction(() => {
					this.shippingLocations.locations = response.data;
				});

				return true;
			}
		} catch (e) {
			reportExceptionToSentry(e, [401, 404]);

			return false;
		} finally {
			runInAction(() => {
				this.shippingLocations.loading = false;
			});
		}
	}

	async addLocation() {
		if (this.shippingLocations.working) return;

		runInAction(() => {
			this.shippingLocations.working = true;
		});

		try {
			const response = await FazaaAPI.User.addLocation({
				fullName: this.shippingLocations.fullName,
				address: this.shippingLocations.address,
				mobile: this.shippingLocations.mobile,
				city: this.shippingLocations.city
			});

			if (response.status === 201) {
				return response.headers.location;
			}
		} catch (e) {
			if (e && e.response && e.response.status === 409) {
				this.setError(this.rootStore.commonStore.translateMessage('locations.error.checkFields'));
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('locations.error.backendError'));
			}

			reportExceptionToSentry(e, [401, 409]);

			return false;
		} finally {
			runInAction(() => {
				this.shippingLocations.working = false;
			});
		}
	}

	async markLocationDefault(id) {
		runInAction(() => {
			this.shippingLocations.working = true;
		});

		try {
			const response = await FazaaAPI.User.defaultLocation(id);

			if (response.status === 200) {
				return true;
			}
		} catch (e) {
			reportExceptionToSentry(e, [401, 404]);

			return false;
		} finally {
			runInAction(() => {
				this.shippingLocations.working = false;
			});
		}
	}

	async markLocationDeleted(id) {
		runInAction(() => {
			this.shippingLocations.working = true;
		});

		try {
			const response = await FazaaAPI.User.deleteLocation(id);

			if (response.status === 200) {
				return true;
			}
		} catch (e) {
			reportExceptionToSentry(e, [401, 404]);

			return false;
		} finally {
			runInAction(() => {
				this.shippingLocations.working = false;
			});
		}
	}

	async ssoTokenRH() {
		try {
			const response = await FazaaAPI.User.ssoTokenRH();

			if (response.status === 200) {
				return response.data;
			}
		} catch (e) {
			reportExceptionToSentry(e, [401, 404]);

			return false;
		}
	}

	async extraOffersSaved() {
		try {
			const response = await FazaaAPI.User.extraOffersSaved();

			if (response.status === 200) {
				return response.data;
			}
		} catch (e) {
			reportExceptionToSentry(e, [401, 404]);

			return false;
		}
	}

	clearChatForm() {
		this.chat.accountNumber = '';
		this.chat.name = '';
		this.chat.email = '';
		this.chat.question = '';
		this.chat.transcript = false;
	}

	cleanupSessionData(switchingContext = false) {
		if (!switchingContext) {
			localStorage.removeItem('token');

			this.authenticated = false;
		}

		this.theme = 'gold';
		this.me = undefined;
		this.principal = '';
		this.secret = '';
		this.passwordResetToken = '';
		this.companyCode = '';
		this.companyName = '';
		this.companyEmail = '';
		this.companyEmailVerified = false;
		this.companyEmailVerificationId = '';
		this.companyCodeId = null;
		this.lastCheckedCompanyCode = null;
		this.email = '';
		this.mobile = '';
		this.mobileVerified = false;
		this.mobileVerificationId = '';
		this.language = '';
		this.emiratesId = '';
		this.emiratesIdExpiry = '';
		this.dob = '';
		this.secretConfirm = '';
		this.smsCode = '';

		clearInterval(this.smsResendInterval);

		this.smsResendInterval = null;
		this.smsResendTimer = 0;
		this.emailCode = '';

		clearInterval(this.emailResendInterval);

		this.emailResendInterval = null;
		this.emailResendTimer = 0;
		this.employeeNo = '';
		this.gender = '';
		this.nameAr = '';
		this.nameEn = '';
		this.personalFunds = '';
		this.salary = '';
		this.nationality = '';
		this.nameFazaa = '';
		this.emirate = '';
		this.subscription = '';
		this.level = '';
		this.firstName = '';
		this.lastName = '';
		this.address = '';
		this.postalCode = '00000';
		this.city = '';
		this.country = '';
		this.ccName = '';
		this.ccNumber = '';
		this.ccExpiry = '';
		this.ccCvv = '';
		this.terms = false;
		this.termsRefunds = false;
		this.oldSecret = '';
		this.uploads = {};
		this.orderId = null;
		this.favoriteCategories = [];
		this.shippingLocations.locale = [];
		this.shippingLocations.fullName = '';
		this.shippingLocations.address = '';
		this.shippingLocations.mobile = '';
		this.shippingLocations.city = '';
		this.chat.accountNumber = '';
		this.chat.name = '';
		this.chat.email = '';
		this.chat.question = '';
		this.chat.transcript = false;
	}

	generateUAEPassState() {
		const state = generateRandomString(8);

		localStorage.setItem('uaePassState', state);

		return state;
	}

	async initiateUAEPassLinking(code) {
		this.setUaePassError(false);
		this.setError(false);
		this.setSuccess(false);
		this.setLoading(true);

		try {
			const response = await FazaaAPI.User.initiateUAEPassLinking(Config.UAE_PASS_CALLBACK_URL + '&code=' + code);

			if (response.status === 200) {
				this.self(true);

				this.setSuccess(true);

				return true;
			}

			this.setUaePassError(this.rootStore.commonStore.translateMessage('page.uaePass.error.generalError'));

			return false;
		} catch (e) {
			if (e && e.response && e.response.data && e.response.data.message) {
				this.setUaePassError(e.response.data.message);
			} else {
				this.setUaePassError(this.rootStore.commonStore.translateMessage('page.uaePass.error.generalError'));
			}

			reportExceptionToSentry(e);

			return false;
		} finally {
			this.setLoading(false);
		}
	}

	/**
	 * @deprecated
	 */
	async unlinkUAEPass(code) {
		this.setUaePassError(false);
		this.setError(false);
		this.setSuccess(false);
		this.setLoading(true);

		try {
			const response = await FazaaAPI.User.unlinkUAEPASS();

			if (response.status === 200) {
				this.self(true);

				this.setSuccess(true);

				return true;
			}

			this.setUaePassError(this.rootStore.commonStore.translateMessage('page.uaePass.error.unlink'));

			return false;
		} catch (e) {
			if (e && e.response && e.response.data && e.response.data.message) {
				this.setUaePassError(e.response.data.message);
			} else {
				this.setUaePassError(this.rootStore.commonStore.translateMessage('page.uaePass.error.unlink'));
			}

			reportExceptionToSentry(e);

			return false;
		} finally {
			this.setLoading(false);
		}
	}

	async initiateUAEPassLogin(code) {
		this.setUaePassError(false);
		this.setError(false);
		this.setSuccess(false);
		this.setLoading(true);

		// Reset status for manual linking eligibility
		this.setUaePassManualLinkEligible(false);

		try {
			const response = await FazaaAPI.User.initiateUAEPassLogin(Config.UAE_PASS_CALLBACK_URL + '&code=' + code);

			if (response.status === 200) {
				this.setUaePassFlowInProgress(true);

				localStorage.setItem('token', response.headers.authorization);
				
				sessionStorage.removeItem('prepaidToken');

				this.setPrincipal('');
				this.setSecret('');
				this.setSuccess(true);

				this.rootStore.prepaidCardStore.setSecure(false);

				return response.data;
			}

			// Automatic linking/loging didn't work and since we didn't get an error response let's assume we can manually link the user
			this.setUaePassManualLinkEligible(true);

			return false;
		} catch (e) {
			if (e && e.response && e.response.data && e.response.data.message) {
				if (e.response.data.message === 'Cannot authenticate uae pass user') {
					this.setUaePassError(this.rootStore.commonStore.translateMessage('page.uaePass.error.cannotAuth'));
				} else if (e.response.data.message === 'No members associated with the uae id') {
					this.setUaePassError(this.rootStore.commonStore.translateMessage('page.uaePass.error.noUAEPassUser'));

					this.setUaePassManualLinkEligible(true);
				} else if (e.response.data.message === 'Member email not unique or not verified') {
					this.setUaePassError(this.rootStore.commonStore.translateMessage('page.uaePass.error.emailNotUniqueVerified'));

					this.setUaePassManualLinkEligible(true);
				} else if (e.response.data.message === 'Member not found') {
					// This error occurs when API tries to locate membership data on legacy API and fails (completely new member sign in attempt)
					this.setUaePassError(this.rootStore.commonStore.translateMessage('page.uaePass.error.legacyMemberNotFound'));

					this.setUaePassManualLinkEligible(true);
				} else {
					this.setUaePassError(e.response.data.message);
				}
			} else {
				this.setUaePassError(this.rootStore.commonStore.translateMessage('page.uaePass.error.generalError'));

				reportExceptionToSentry(e);
			}

			return false;
		} finally {
			this.setLoading(false);
		}
	}

	async switchToLinkedMemberContext(memberId) {
		this.setUaePassError(false);
		this.setError(false);
		this.setSuccess(false);
		this.setLoading(true);
		this.setSwitchingContext(true);

		try {
			this.rootStore.cleanupSessionData(true);

			const response = await FazaaAPI.User.switchToLinkedMemberContext(memberId);

			if (response.status === 200) {
				this.setSuccess(true);

				return true;
			}

			this.setUaePassError(this.rootStore.commonStore.translateMessage('page.uaePass.error.switchMemberContextErrorGeneral'));

			return false;
		} catch (e) {
			if (e && e.response && e.response.data && e.response.data.message) {
				this.setUaePassError(e.response.data.message);
			} else {
				this.setUaePassError(this.rootStore.commonStore.translateMessage('page.uaePass.error.switchMemberContextErrorGeneral'));
			}

			reportExceptionToSentry(e);

			return false;
		} finally {
			this.setLoading(false);
			this.setSwitchingContext(false);
		}
	}

	async completeUAEPassLogin() {
		if (await this.self()) {
			this.setAuthenticated(true);

			Sentry.configureScope((scope) => {
				scope.setUser({
					id: this.me.id,
					email: this.me.email,
					extra: {
						memberId: this.me.memberId,
						membershipNumber: this.me.membershipNumber
					}
				});
			});

			this.setUaePassFlowInProgress(false);

			return true;
		} else {
			this.signOut();

			this.setUaePassFlowInProgress(false);

			return false;
		}
	}

	async unlinkMembership(number, code) {
		this.setError(false);
		this.setSuccess(false);
		this.setLoading(true);

		try {
			const response = await FazaaAPI.User.unlinkMembership(number, code);

			if (response.status === 200) {
				this.setSuccess(this.rootStore.commonStore.translateMessage('page.unlink.success'));

				this.self(true);
			} else {
				this.setError(this.rootStore.commonStore.translateMessage('page.unlink.error'));
			}
		} catch (e) {
			this.setError(this.rootStore.commonStore.translateMessage('page.unlink.error'));

			reportExceptionToSentry(e, [404]);
		} finally {
			this.setLoading(false);
		}
	}

	async cancelMembershipRequest(membership_id, reason, ssoToken) {
		this.setError(false);
		this.setSuccess(false);
		this.setLoading(true);

		try {
			const params = {
				membership_id,
				reason
			};

			const response = await FazaaAPI.Fazaa.cancelMembership(params, ssoToken);

			this.setSuccess(this.rootStore.commonStore.translateMessage('page.cancelMembership.success'));
		} catch (e) {
			this.setError(this.rootStore.commonStore.translateMessage('page.cancelMembership.error'));

			reportExceptionToSentry(e);
		} finally {
			this.setLoading(false);
		}
	}

}

decorate(UserStore, {
	threeDSSetupInProgress: observable,
	authenticated: observable,
	theme: observable,
	me: observable,
	position: observable,
	positionFromIP: observable,
	countries: observable,
	subscriptions: observable,
	levels: observable,
	principal: observable,
	secret: observable,
	passwordResetToken: observable,
	companyCode: observable,
	companyName: observable,
	companyEmail: observable,
	registrationOpen: observable,
	verifyCompanyEmailOpen: observable,
	personalInformationOpen: observable,
	smsVerificationOpen: observable,
	additionalInformationOpen: observable,
	plansPaymentOpen: observable,
	email: observable,
	mobile: observable,
	mobileVerificationId: observable,
	mobileVerified: observable,
	companyEmailVerificationId: observable,
	companyEmailVerified: observable,
	language: observable,
	emiratesId: observable,
	emiratesIdExpiry: observable,
	dob: observable,
	secretConfirm: observable,
	smsCode: observable,
	smsResendTimer: observable,
	emailCode: observable,
	emailResendTimer: observable,
	employeeNo: observable,
	gender: observable,
	nameAr: observable,
	nameEn: observable,
	personalFunds: observable,
	salary: observable,
	nationality: observable,
	nameFazaa: observable,
	emirate: observable,
	level: observable,
	subscription: observable,
	firstName: observable,
	lastName: observable,
	address: observable,
	postalCode: observable,
	city: observable,
	country: observable,
	ccName: observable,
	ccNumber: observable,
	ccExpiry: observable,
	ccCvv: observable,
	terms: observable,
	termsRefunds: observable,
	businessName: observable,
	businessEmail: observable,
	businessPhone: observable,
	oldSecret: observable,
	uaePassError: observable,
	uaePassManualLinkEligible: observable,
	error: observable,
	success: observable,
	loading: observable,
	switchingContext: observable,
	uaePassFlowInProgress: observable,
	errors: observable,
	threeDSSetupInProgress: observable,
	threeDSSetupComplete: observable,
	redirectTo: observable,
	redirectUrl: observable,
	redirectRequiresProfile: observable,
	shippingLocations: observable,
	chat: observable,
	setAuthenticated: action,
	setTheme: action,
	setMe: action,
	setPosition: action,
	setPositionFromIP: action,
	setCountries: action,
	setSubscriptions: action,
	setLevels: action,
	setRegistrationOpen: action,
	setVerifyCompanyEmailOpen: action,
	setPersonalInformationOpen: action,
	setSmsVerificationOpen: action,
	setAdditionalInformationOpen: action,
	setPlansPaymentOpen: action,
	setEmail: action,
	setCompanyEmail: action,
	setMobile: action,
	setMobileVerificationId: action,
	setMobileVerified: action,
	setCompanyEmailVerificationId: action,
	setCompanyEmailVerified: action,
	setLanguage: action,
	setEmiratesId: action,
	setEmiratesIdExpiry: action,
	setDob: action,
	setSecretConfirm: action,
	setSmsCode: action,
	setEmailCode: action,
	setSmsResendTimer: action,
	setEmailResendTimer: action,
	setEmployeeNo: action,
	setGender: action,
	setNameAr: action,
	setNameEn: action,
	setPersonalFunds: action,
	setSalary: action,
	setNationality: action,
	setNameFazaa: action,
	setEmirate: action,
	setLevel: action,
	setSubscription: action,
	setFirstName: action,
	setLastName: action,
	setAddress: action,
	setPostalCode: action,
	setCity: action,
	setCountry: action,
	setCcName: action,
	setCcNumber: action,
	setCcExpiry: action,
	setCcCvv: action,
	setTerms: action,
	setTermsRefunds: action,
	setPrincipal: action,
	setSecret: action,
	setPasswordResetToken: action,
	setCompanyCode: action,
	setCompanyName: action,
	setBusinessName: action,
	setBusinessEmail: action,
	setBusinessPhone: action,
	setOldSecret: action,
	setUaePassError: action,
	setUaePassManualLinkEligible: action,
	setError: action,
	setSuccess: action,
	setLoading: action,
	setSwitchingContext: action,
	setUaePassFlowInProgress: action,
	setErrors: action,
	setThreeDSSetupInProgress: action,
	setThreeDSSetupComplete: action,
	setRedirectTo: action,
	setRedirectUrl: action,
	setRedirectRequiresProfile: action,
	signIn: action,
	signOut: action,
	suggestPartner: action,
	resetRegistrationFields: action,
	register: action,
	changePassword: action,
	loadProfileFields: action,
	updateProfile: action,
	clearErrors: action,
	addError: action,
	clearError: action,
	isProfileComplete: computed,
	isPasswordChangeNeeded: computed,
	verifyProfile: action,
	registerParamsHash: computed,
	showSessionTimeoutWarning: action,
	hideSessionTimeoutWarning: action,
	sessionTimeoutWarningVisible: observable,
	isCategoryOnboardingDone: computed,
	showFavoriteCategories: action,
	hideFavoriteCategories: action,
	favoriteCategoriesVisible: observable,
	loadFavoriteCategories: action,
	toggleFavoriteCategory: action,
	favoriteCategories: observable,
	uploads: observable,
	clearUploads: action,
	addUpload: action,
	lastCheckedCompanyCode: observable,
	loadLocations: action,
	clearLocationForm: action,
	addLocation: action,
	markLocationDefault: action,
	markLocationDeleted: action,
	clearChatForm: action,
	cleanupSessionData: action,
	generateUAEPassState: action,
	initiateUAEPassLinking: action,
	initiateUAEPassLogin: action,
	switchToLinkedMemberContext: action,
	completeUAEPassLogin: action,
	cancelMembershipRequest: action
});

export default UserStore;
