import * as Sentry from '@sentry/browser';

/**
 * 
 * @param {number} lat1 
 * @param {number} lon1 
 * @param {number} lat2 
 * @param {number} lon2 
 */
export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
	function deg2rad(deg) {
		return deg * (Math.PI / 180);
	}

	var R = 6371; // Radius of the Earth in km
	var dLat = deg2rad(lat2 - lat1);
	var dLon = deg2rad(lon2 - lon1); 
	var a = 
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
		Math.sin(dLon / 2) * Math.sin(dLon / 2); 
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); 
	var d = R * c; // Distance in km

	return d;
}

/**
 * 
 * @param {any} exception 
 * @param {array} additionalToExclude
 */
export function reportExceptionToSentry(e, additionalToExclude = []) {
	// Do not report HTTP 504, 543 to Sentry
	if (e && e.response && e.response.status) {
		if (e.response.status === 504 || e.response.status == 543) {
			return;
		}

		if (additionalToExclude.includes(e.response.status)) {
			return;
		}
	}

	const xTraceId = e && e.response && e.response.headers ? e.response.headers['x-trace-id'] : null;

	Sentry.withScope(scope => {
		scope.setExtras({
			response: e.response,
			xTraceId: xTraceId
		});

		Sentry.captureException(e);
	});
}

export function generateRandomString(length = 8) {
	return Math.random().toString(36).slice(2, length);
}

export function initiate3DSDataColletion(url, jwt) {
	// Delete iframe and form if they exist

	let iframe = document.getElementById('cardinal_collection_iframe');
	
	if (iframe) {
		iframe.remove();
	}

	iframe = document.createElement('iframe');

	iframe.setAttribute('id', 'cardinal_collection_iframe');
	iframe.setAttribute('name', 'collectionIframe');
	iframe.setAttribute('height', 10);
	iframe.setAttribute('width', 10);
	iframe.setAttribute('style', 'display: none;');

	document.body.appendChild(iframe);

	let form = document.getElementById("cardinal_collection_form");

	if (form) {
		form.remove();
	}
	
	form = document.createElement('form');

	form.setAttribute('id', 'cardinal_collection_form');
	
	form.setAttribute('method', 'POST');
	form.setAttribute('target', 'collectionIframe');
	form.setAttribute('action', url);
	form.setAttribute('style', 'display: none;');

	const input = document.createElement('input');

	input.setAttribute('id', 'cardinal_collection_form_input');
	input.setAttribute('type', 'hidden');
	input.setAttribute('name', 'JWT');
	input.setAttribute('value', jwt);

	form.appendChild(input);

	document.body.appendChild(form);

	form.submit();
}

// Not working
/**
 * 
 * @param {*} acsUrl 
 * @param {*} pareq 
 * @param {*} termUrl 
 * 
 * @deprecated
 */
export function initiate3DSChallenge(acsUrl, pareq, termUrl) {
	const form = document.createElement('form');

	form.setAttribute('method', 'POST');
	form.setAttribute('action', acsUrl);
	form.setAttribute('style', 'display: none;');

	const pareqInput = document.createElement('input');

	pareqInput.setAttribute('type', 'hidden');
	pareqInput.setAttribute('name', 'PaReq');
	pareqInput.setAttribute('value', pareq);

	form.appendChild(pareqInput);

	const termUrlInput = document.createElement('input');

	termUrlInput.setAttribute('type', 'hidden');
	termUrlInput.setAttribute('name', 'TermUrl');
	termUrlInput.setAttribute('value', termUrl);

	form.appendChild(termUrlInput);

	document.body.appendChild(form);

	form.submit();
}
