import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import FormRadioButton from '../../base/form/FormRadioButton';
import FormCheckboxButton from '../../base/form/FormChecboxButton';
import Emirates from '../../../types/Emirates';
import CreditCardInput from 'react-credit-card-input';
import { autorun } from 'mobx';
import Localized from '../../base/i18n/Localized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import FormError from '../../base/form/FormError';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDateInput from '../../base/form/CustomDateInput';
import Modal from 'react-modal';
import { initiate3DSDataColletion } from '../../../utils/Utils';

import imgCardPreview from '../../../assets/img/card-preview.jpg';

moment.tz.setDefault('Asia/Dubai');

//const VALID_EMAIL = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
const VALID_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const VALID_MOBILE = /^\+971 5[024568] [0-9]{3} [0-9]{4}$/;
//const VALID_UAE_ID = /^784-[0-9]{4}-[0-9]{7}-[0-9]{1}$/;
const VALID_UAE_ID = /^784-[0-9]{4}-[0-9]{7}-[0-9]{1,2}$/;

const PASSWORD_RULE_LOWERCASE = /(?=.*[a-z])/;
const PASSWORD_RULE_UPPERCASE = /(?=.*[A-Z])/;
const PASSWORD_RULE_NUMBER = /(?=.*[0-9])/;
const PASSWORD_RULE_SPECIAL = /(?=.*[^A-Za-z0-9])/;
const PASSWORD_RULE_LENGTH = /(?=.{8,})/;

const SignUp = inject('rootStore')(observer(
	class SignUp extends Component {

		constructor(props) {
			super(props);

			this.signupRef = React.createRef();
			this.cardPreviewRef = React.createRef();

			this.handleCompanyCodeProceed = this.handleCompanyCodeProceed.bind(this);
			this.handleCompanyEmailVerificationProceed = this.handleCompanyEmailVerificationProceed.bind(this);
			this.handlePersonalProceed = this.handlePersonalProceed.bind(this);
			this.handleResendSmsVerificationCode = this.handleResendSmsVerificationCode.bind(this);
			this.handleSendEmailVerificationCode = this.handleSendEmailVerificationCode.bind(this);
			this.handleSmsVerificationProceed = this.handleSmsVerificationProceed.bind(this);
			this.handleAdditionalInformationProceed = this.handleAdditionalInformationProceed.bind(this);
			this.handleSignUpClick = this.handleSignUpClick.bind(this);
			this.handleGoToStep = this.handleGoToStep.bind(this);

			this.signupProgressStep = this.signupProgressStep.bind(this);
			this.levelClasses = this.levelClasses.bind(this);
			this.scrollToTop = this.scrollToTop.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.signUp.title');
			});

			this.state = {
				cpFontSize: 22,
				cpTop: 120,
				cpLeft: 45,
				passwordRuleLowercase: false,
				passwordRuleUppercase: false,
				passwordRuleNumber: false,
				passwordRuleSpecial: false,
				passwordRuleLength: false,

				// 3DS
				challengeOpen: false,
				stepUpUrl: null,
				accessToken: null
			};

			// Used as device fingerprinting reference
			this.referenceId = null;
			this.authenticationTransactionId = null;

			// Data collection (these need to be reset after each attempt!)
			this.dataCollectionTimeout = null;
			this.dataCollectionCompleted = false;
			this.enrollmentCheckInitiated = false;
		}

		componentDidMount() {
			const { userStore, contentStore } = this.props.rootStore;

			if (userStore.authenticated) {
				this.props.history.replace('/');
			}

			userStore.resetRegistrationFields();

			userStore.loadCountries().then(result => {
				if (!result) {
					userStore.setError('Unable to get list of countries/nationalities, please reload the page and try again.');
				}
			});

			userStore.loadSubscriptions().then(result => {
				if (!result) {
					userStore.setError('Unable to get list of subscriptions, please reload the page and try again.');
				} else {
					// Set default subscription
					userStore.setSubscription(String(userStore.subscriptions.find(s => s.subscriptionMonths === 12).id));
				}
			});

			userStore.loadLevels().then(result => {
				if (!result) {
					userStore.setError('Unable to get list of levels, please reload the page and try again.');
				}
			});

			contentStore.loadContent('levelFeaturesDiscount', 'levelFeaturesSilver', 'levelFeaturesGold', 'levelFeaturesPlatinum', 'signUpTos');

			window.addEventListener('resize', this.handleResize);

			this.handleResize();

			// 3DS stuff
			// Setup listener for when data collection is complete
			window.addEventListener('message', this.handleWindowMessage, false);
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();

			window.removeEventListener('resize', this.handleResize);
			window.removeEventListener('message', this.handleWindowMessage);
		}

		handleWindowMessage = event => {
			const { commonStore, userStore } = this.props.rootStore;
			const { translateMessage } = commonStore;

			if (event.origin === 'https://centinelapistag.cardinalcommerce.com' ||
					event.origin === 'https://centinelapi.cardinalcommerce.com')
			{
				console.debug('Data collection event message: ', event.data);

				const messageData = JSON.parse(event.data);
				
				if (messageData.MessageType === 'profile.completed' &&
						messageData.Status === true)
				{
					console.debug('Attempting to call enrollment check due to data collection message being received.')

					this.dataCollectionCompleted = true;

					clearTimeout(this.dataCollectionTimeout);

					this.doEnrollmentCheck(messageData);
				} else {
					userStore.setError(translateMessage('generic.error.threeDSDataCollectionError'));
				}
			}
		};

		doEnrollmentCheck = data => {	
			if (this.enrollmentCheckInitiated) {
				return;
			}

			this.enrollmentCheckInitiated = true;

			// Process response and initiate payment response via new V2 payment API
			// Based on response the payment will either go thru as before or there will be a need
			// to challenge the user by showing a popup with an iframe we post data too
			// Iframe load event will need to be monitored and intercepted for the challenge result,
			// after which we hide the iframe and finish the payment with the auth transaction ID we receive

			const { userStore } = this.props.rootStore;

			userStore.setThreeDSSetupInProgress(false);

			userStore.register(this.referenceId).then(response => {
				if (response.status === "CHALLENGE_REQUIRED") {
					this.authenticationTransactionId = response.data.authenticationTransactionId;

					this.setState({
						challengeOpen: true,
						stepUpUrl: response.data.stepUpUrl,
						accessToken: response.data.accessToken
					});
				}
			});
		};

		handleResize = () => {
			const baseWidth = 485;

			if (this.cardPreviewRef.current) {
				const cr = this.cardPreviewRef.current.getBoundingClientRect();

				const wChangePercent = cr.width * 100 / baseWidth;

				this.setState({
					cpFontSize: 22 * (wChangePercent / 100),
					cpTop: 120 * (wChangePercent / 100),
					cpLeft: 45 * (wChangePercent / 100)
				});
			}
		}

		handleCompanyCodeChange = e => {
			this.props.rootStore.userStore.setCompanyCode(e.target.value.toUpperCase());
			this.props.rootStore.userStore.setCompanyName('');
			this.props.rootStore.userStore.companyCodeId = null;

			this.props.rootStore.userStore.setCompanyEmailVerified(false);
			this.props.rootStore.userStore.setCompanyEmailVerificationId('');
		}
		handleCompanyEmailChange = e => {
			this.props.rootStore.userStore.setCompanyEmail(e.target.value ? String(e.target.value).toLowerCase() : '');
		}
		handleEmailChange = e => this.props.rootStore.userStore.setEmail(e.target.value ? String(e.target.value).toLowerCase() : '');
		handleMobileChange = e => { 
			if (e.target.validity.valid) {
				this.props.rootStore.userStore.setMobile(e.target.value);
			}
		}
		handleLanguageChange = e => this.props.rootStore.userStore.setLanguage(e.target.value);
		handleEmiratesIdChange = e => this.props.rootStore.userStore.setEmiratesId(e.target.value);
		handleEmiratesIdExpiryChange = date => this.props.rootStore.userStore.setEmiratesIdExpiry(moment(date).format('DD-MM-YYYY'));
		handleDobChange = date => this.props.rootStore.userStore.setDob(moment(date).format('DD-MM-YYYY'));

		handleSecretChange = e => {
			const { userStore } = this.props.rootStore;

			userStore.setSecret(e.target.value);

			this.setState({
				passwordRuleLowercase: PASSWORD_RULE_LOWERCASE.test(userStore.secret),
				passwordRuleUppercase: PASSWORD_RULE_UPPERCASE.test(userStore.secret),
				passwordRuleNumber: PASSWORD_RULE_NUMBER.test(userStore.secret),
				passwordRuleSpecial: PASSWORD_RULE_SPECIAL.test(userStore.secret),
				passwordRuleLength: PASSWORD_RULE_LENGTH.test(userStore.secret),
			});
		}

		handleSecretConfirmChange = e => {
			const { userStore } = this.props.rootStore;

			userStore.setSecretConfirm(e.target.value);
		}

		handleSmsCodeChange = e => this.props.rootStore.userStore.setSmsCode(e.target.value);
		handleEmailCodeChange = e => this.props.rootStore.userStore.setEmailCode(e.target.value);
		handleEmployeeNoChange = e => {
			if (e.target.validity.valid) {
				this.props.rootStore.userStore.setEmployeeNo(e.target.value);
			}
		}
		handleGenderChange = e => this.props.rootStore.userStore.setGender(e.target.value);
		handleNameArabicChange = e => {
			if (e.target.validity.valid) {
				this.props.rootStore.userStore.setNameAr(e.target.value);
			}
		}
		handleNameEnglishChange = e => {
			if (e.target.validity.valid) {
				this.props.rootStore.userStore.setNameEn(e.target.value);
			}
		}
		handlePersonalFundsChange = e => this.props.rootStore.userStore.setPersonalFunds(e.target.value);
		handleSalaryChange = e => this.props.rootStore.userStore.setSalary(e.target.value);
		handleNationalityChange = e => this.props.rootStore.userStore.setNationality(e.target.value);
		handleNameFazaaChange = e => {
			if (e.target.validity.valid) {
				this.props.rootStore.userStore.setNameFazaa(e.target.value);
			}
		}
		handleEmirateChange = e => this.props.rootStore.userStore.setEmirate(e.target.value);
		handleSubscriptionChange = e => this.props.rootStore.userStore.setSubscription(e.target.value);
		handleLevelChange = id => this.props.rootStore.userStore.setLevel(id);
		handleFirstNameChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setFirstName(e.target.value);
			}
		}
		handleLastNameChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setLastName(e.target.value);
			}
		}
		handleAddressChange = e => {
			if (new RegExp('^[a-zA-Z\\d\\-\\s/\\.]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setAddress(e.target.value);
			}
		}
		handlePostalCodeChange = e => {
			if (new RegExp('^[a-zA-Z\\d\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setPostalCode(e.target.value);
			}
		}
		handleCityChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setCity(e.target.value);
			}
		}
		handleCountryChange = e => this.props.rootStore.userStore.setCountry(e.target.value);
		handleCcNumberChange = e => this.props.rootStore.userStore.setCcNumber(e.target.value);
		handleCcExpiryChange = e => this.props.rootStore.userStore.setCcExpiry(e.target.value);
		handleCcCvvChange = e => this.props.rootStore.userStore.setCcCvv(e.target.value);
		handleTermsChange = e => this.props.rootStore.userStore.setTerms(e.target.checked);
		handleTermsRefundsChange = e => this.props.rootStore.userStore.setTermsRefunds(e.target.checked);

		getPasswordRuleClass = rule => {
			return rule ? 'password-strength__rule--success' : '';
		};

		getPasswordRuleIcon = rule => {
			return rule ? faCheckCircle : faTimesCircle;
		};

		isPasswordStrong = () => {
			const { passwordRuleLowercase, passwordRuleUppercase, passwordRuleNumber, passwordRuleSpecial, passwordRuleLength } = this.state;

			return passwordRuleLowercase && passwordRuleUppercase && passwordRuleNumber && passwordRuleSpecial && passwordRuleLength;
		};

		handleCompanyCodeProceed(e) {
			e.preventDefault();

			const { userStore, commonStore } = this.props.rootStore;
			const { translateMessage, locale } = commonStore;

			userStore.clearErrors();

			userStore.checkCompanyCode().then(result => {
				if (result) {
					if (!!result.companyAllowSignup) {
						userStore.companyCodeId = result.id;
						userStore.setCompanyName(locale === 'ar' ? result.companyArName : result.companyEnName);
						userStore.setError(false);

						if (!!result.companyVerifyEmail && !userStore.companyEmailVerified) {
							userStore.setVerifyCompanyEmailOpen(true);
						} else {
							userStore.setRegistrationOpen(true);
							userStore.setPersonalInformationOpen(true);
						}

						if (result.companyAllowDiscountSignup) {
							userStore.setLevel(4);
						} else {
							userStore.setLevel(3);
						}
					} else {
						userStore.setError(translateMessage('page.signup.error.disabledCompanyCode'));	
					}
				} else {
					userStore.setError(translateMessage('page.signup.error.invalidCompanyCode'));
				}

				this.scrollToTop();
			});
		}

		handleSendEmailVerificationCode(e) {
			e.preventDefault();

			const { commonStore, userStore } = this.props.rootStore;
			const { companyEmail } = userStore;
			const { translateMessage } = commonStore;

			userStore.clearErrors();

			// Validate
			// - Email (RFC2822)
			if (!VALID_EMAIL.test(companyEmail)) {
				userStore.addError('companyEmail', translateMessage('generic.error.invalidEmail'));
			}

			if (userStore.errors.length === 0) {
				userStore.sendCompanyEmail();
			} else {
				userStore.setError(translateMessage('generic.error.checkFields'));
			}
		}

		async handleCompanyEmailVerificationProceed(e) {
			e.preventDefault();

			const { userStore }	= this.props.rootStore;
			const { companyEmailVerified } = userStore;

			userStore.clearErrors();

			if (companyEmailVerified || await userStore.verifyCompanyEmail()) {
				userStore.setError(false);
				userStore.setVerifyCompanyEmailOpen(false);
				userStore.setRegistrationOpen(true);
				userStore.setPersonalInformationOpen(true);

				userStore.setEmailCode('');

				this.scrollToTop();
			}
		}

		async handlePersonalProceed(e) {
			e.preventDefault();

			const { commonStore, userStore } = this.props.rootStore;
			const { translateMessage } = commonStore;
			const { email, mobile, emirate, emiratesId, emiratesIdExpiry, dob, language, secret, secretConfirm } = userStore;

			userStore.clearErrors();

			// Validate
			// - Email (RFC2822)
			if (!VALID_EMAIL.test(email)) {
				userStore.addError('email', translateMessage('generic.error.invalidEmail'));
			}

			// - Email (check if it's in use)
			if (VALID_EMAIL.test(email)) {
				if (!await userStore.checkEmail()) {
					userStore.addError('email', translateMessage('generic.error.emailInUse'));
				}
			}
			
			// - Mobile (UAE numbers)
			if (!VALID_MOBILE.test(mobile)) {
				userStore.addError('mobile', translateMessage('generic.error.invalidMobile'));
			}

			// - Mobile (check if it's in use)
			if (VALID_MOBILE.test(mobile)) {
				if (!await userStore.checkMobile()) {
					userStore.addError('mobile', translateMessage('generic.error.mobileInUse'));
				}
			}

			// - Emirate
			if (!emirate) {
				userStore.addError('emirate', translateMessage('generic.error.emirateNotSelected'));
			}

			// - UAE ID
			if (!VALID_UAE_ID.test(emiratesId)) {
				userStore.addError('emiratesId', translateMessage('generic.error.invalidEmiratesId'));
			}

			if (VALID_UAE_ID.test(emiratesId)) {
				if (!await userStore.checkEmiratesId()) {
					userStore.addError('emiratesId', translateMessage('generic.error.emiratesIdInUse'));
				}
			}

			// - UAE ID expiry date
			((date) => {
				if (!date.isValid()) {
					userStore.addError('emiratesIdExpiry', translateMessage('generic.error.invalidDate'));
				}
	
				if (date.isBefore()) {
					userStore.addError('emiratesIdExpiry', translateMessage('generic.error.invalidDatePast'));	
				}
			})(moment(emiratesIdExpiry, 'DD-MM-YYYY', true));

			// - Date of birth
			((date) => {
				if (!date.isValid()) {
					userStore.addError('dob', translateMessage('generic.error.invalidDate'));
				}
	
				if (date.isAfter()) {
					userStore.addError('dob', translateMessage('generic.error.invalidDateFuture'));	
				}
			})(moment(dob, 'DD-MM-YYYY', true));

			// - Preferred language
			if (!language) {
				userStore.addError('language', translateMessage('generic.error.languageNotSelected'));
			}

			// - Password length
			if (secret.length < 8) {
				userStore.addError('secret', translateMessage('generic.error.passwordLength', {
					minLength: 8
				}));
			}
			
			// - Password matching
			if (secret !== secretConfirm) {
				userStore.addError('secretConfirm', translateMessage('generic.error.secretMatch'));
			}

			if (userStore.errors.length === 0 && this.isPasswordStrong()) {
				userStore.setPersonalInformationOpen(false);

				if (!userStore.mobileVerified) {
					userStore.sendSms();

					userStore.setSmsVerificationOpen(true);
				} else {
					userStore.setAdditionalInformationOpen(true);
				}
			} else {
				userStore.setError(translateMessage('generic.error.checkFields'));
			}

			this.scrollToTop();
		}

		handleResendSmsVerificationCode(e) {
			e.preventDefault();

			const { userStore }	= this.props.rootStore;

			userStore.clearErrors();
			userStore.sendSms();
		}

		async handleSmsVerificationProceed(e) {
			e.preventDefault();

			const { userStore }	= this.props.rootStore;
			const { mobileVerified } = userStore;

			userStore.clearErrors();

			if (mobileVerified || await userStore.verifySms()) {
				userStore.setSmsVerificationOpen(false);
				userStore.setAdditionalInformationOpen(true);

				this.scrollToTop();
			}
		}

		handleAdditionalInformationProceed(e) {
			e.preventDefault();

			const { commonStore, userStore } = this.props.rootStore;
			const { translateMessage } = commonStore;
			const { nameAr, nameEn, nameFazaa } = userStore;

			userStore.clearErrors();

			// Validate
			// Full names have to contain at least one space character
			if (nameAr && !nameAr.trim().includes(' ')) {
				userStore.addError('nameAr', translateMessage('generic.error.nameMustContainSpace'));
			}

			if (!nameEn.trim().includes(' ')) {
				userStore.addError('nameEn', translateMessage('generic.error.nameMustContainSpace'));
			}

			// Name on card has to contain at least one space character
			if (!nameFazaa.trim().includes(' ')) {
				userStore.addError('nameFazaa', translateMessage('generic.error.nameMustContainSpace'));
			}

			if (userStore.errors.length === 0) {
				userStore.setAdditionalInformationOpen(false);
				userStore.setPlansPaymentOpen(true);
			} else {
				userStore.setError(translateMessage('generic.error.checkFields'));
			}

			this.scrollToTop();
		}

		async handleSignUpClick(e) {
			e.preventDefault();

			const { userStore, commonStore } = this.props.rootStore;
			const { translateMessage } = commonStore;

			userStore.clearErrors();

			this.dataCollectionCompleted = false;
			this.enrollmentCheckInitiated = false;

			let error = false;

			if (!await userStore.checkEmail()) {
				userStore.addError('email', translateMessage('generic.error.emailInUse'));

				error = true;
			}

			if (!await userStore.checkMobile()) {
				userStore.addError('mobile', translateMessage('generic.error.mobileInUse'));

				error = true;
			}

			if (!await userStore.checkEmiratesId()) {
				userStore.addError('emiratesId', translateMessage('generic.error.emiratesIdInUse'));

				error = true;
			}

			if (!error) {
				userStore.registerSetup().then(response => {
					// IMPORTANT: We need to check if level is 4 then no payment is due!!!
					if (userStore.level === 4) {
						userStore.setThreeDSSetupInProgress(false);
						
						this.scrollToTop();

						return;
					}

					if (response && response.deviceDataCollectionUrl && response.accessToken) {
						// Create iframe, post to iframe and wait for event
						this.referenceId = response.referenceId;

						initiate3DSDataColletion(response.deviceDataCollectionUrl, response.accessToken);

						// Call after 10 seconds
						this.dataCollectionTimeout = setTimeout(() => {
							console.debug('Calling enrollment check due to timeout on data collection.')

							this.doEnrollmentCheck();
						}, 10000);
					} else {
						userStore.setThreeDSSetupInProgress(false);
					}
				});
			} else {
				this.handleGoToStep(2);
			}

			this.scrollToTop();
		}

		handleGoToStep(step) {
			const { userStore } = this.props.rootStore;

			if (step === 1) {
				userStore.setPlansPaymentOpen(false);
				userStore.setAdditionalInformationOpen(false);
				userStore.setSmsVerificationOpen(false);
				userStore.setPersonalInformationOpen(false);
				userStore.setVerifyCompanyEmailOpen(false);
				userStore.setRegistrationOpen(false);
			}

			if (step === 2) {
				userStore.setPlansPaymentOpen(false);
				userStore.setAdditionalInformationOpen(false);
				userStore.setSmsVerificationOpen(false);
				userStore.setPersonalInformationOpen(true);
			}

			if (step === 4) {
				userStore.setPlansPaymentOpen(false);
				userStore.setAdditionalInformationOpen(true);
			}

			this.scrollToTop();
		}

		signupProgressStep() {
			const { registrationOpen, personalInformationOpen, smsVerificationOpen, additionalInformationOpen, plansPaymentOpen } = this.props.rootStore.userStore;

			if (!registrationOpen) return 1;
			if (personalInformationOpen) return 2;
			if (smsVerificationOpen) return 3;
			if (additionalInformationOpen) return 4;
			if (plansPaymentOpen) return 5;
		}

		levelClasses(id) {
			const { level } = this.props.rootStore.userStore;

			if (!level) {
				return '';
			} else {
				if (level === id) {
					return 'signup__level--selected';
				} else {
					return 'signup__level--not-selected';
				}
			}
		}

		scrollToTop() {
			if (this.signupRef.current) {
				requestAnimationFrame(() => {
					this.signupRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
				});
			}
		}

		redirectHandler = event => {
			try {
				if (event.target.contentWindow.location.href.includes('account/upgrade')) {
					this.setState({
						challengeOpen: false
					});

					// Try to complete payment
					const { userStore } = this.props.rootStore;
					
					userStore.upgradeComplete(this.authenticationTransactionId);
				}
			} catch (e) {

			}
		}

		render() {
			const { locale, languages, translateMessage } = this.props.rootStore.commonStore;
			const { companyCode, companyName, registrationOpen, verifyCompanyEmailOpen, personalInformationOpen, smsVerificationOpen, additionalInformationOpen, plansPaymentOpen, companyEmail, emailCode, companyEmailVerified, emailResendTimer, email, mobile, mobileVerified, language, emiratesId, emiratesIdExpiry, dob, secret, secretConfirm, smsCode, smsResendTimer, employeeNo, gender, nameAr, nameEn, personalFunds, salary, nationality, countries, nameFazaa, emirate, levels, subscriptions, subscription, level, firstName, lastName, address, postalCode, city, country, ccNumber, ccExpiry, ccCvv, terms, termsRefunds, error, success, loading, errors, lastCheckedCompanyCode, companyEmailVerificationId, threeDSSetupInProgress } = this.props.rootStore.userStore;
			const personalProceedDisabled = !(companyCode && email && mobile && language && emiratesId && emiratesIdExpiry && dob && secret && secretConfirm && (secret === secretConfirm) && emirate);
			const additionalProceedDisabled = !(/*employeeNo &&*/ gender /*&& nameAr*/ && nameEn && personalFunds && salary && nationality && nameFazaa);
			//const signUpDisabled = !(subscription && level && firstName && lastName && address && postalCode && city && country && ccNumber && ccExpiry && ccCvv && terms);
			//const freeMembership = companyCode ? !!companyCode.companyMembershipFree : false;
			const freeMembership = false;
			const signUpDisabled = !subscription || !level || !terms || ((level !== 4 && !freeMembership) && (!firstName || !lastName || !address || !postalCode || !city || !country || !ccNumber || !ccExpiry || !ccCvv || !termsRefunds));
			const emiratesIdExpiryDate = emiratesIdExpiry ? moment(emiratesIdExpiry, 'DD-MM-YYYY', true).toDate() : null;
			const dobDate = dob ? moment(dob, 'DD-MM-YYYY', true).toDate() : null;

			const step = this.signupProgressStep();
			const subscriptionInfo = subscriptions.find(s => String(s.id) === subscription);
			const { contentStore } = this.props.rootStore;
			const content = {
				levelFeaturesDiscount: contentStore.getContent('levelFeaturesDiscount'),
				levelFeaturesSilver: contentStore.getContent('levelFeaturesSilver'),
				levelFeaturesGold: contentStore.getContent('levelFeaturesGold'),
				levelFeaturesPlatinum: contentStore.getContent('levelFeaturesPlatinum'),
				signUpTos: contentStore.getContent('signUpTos')
			};

			const companyDomain = lastCheckedCompanyCode ? lastCheckedCompanyCode.companyEmailDomain : null;
			const allowDiscount = lastCheckedCompanyCode ? !!lastCheckedCompanyCode.companyAllowDiscountSignup : false;
			const { cpFontSize, cpTop, cpLeft } = this.state;
			const { passwordRuleLowercase, passwordRuleUppercase, passwordRuleNumber, passwordRuleSpecial, passwordRuleLength } = this.state;

			return (
				<section ref={this.signupRef} className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.signUp.title" />
					</h2>

					<div className="section__wrapper">
						<div className={`signup-progress signup-progress--step-${step}`}>
							<div className="signup-progress__step signup-progress__step--active">
								<div className="signup-progress__number">1</div>
								<div className="signup-progress__text">
									<Localized code="page.signup.text.step.companyCode" />
								</div>
							</div>

							<div className={`signup-progress__step ${step >= 2 ? 'signup-progress__step--active' : ''}`}>
								<div className="signup-progress__number">2</div>
								<div className="signup-progress__text">
									<Localized code="page.signup.text.step.personalInformation" />
								</div>
							</div>

							<div className={`signup-progress__step ${step >= 3 ? 'signup-progress__step--active' : ''}`}>
								<div className="signup-progress__number">3</div>
								<div className="signup-progress__text">
									<Localized code="page.signup.text.step.smsVerification" />
								</div>
							</div>

							<div className={`signup-progress__step ${step >= 4 ? 'signup-progress__step--active' : ''}`}>
								<div className="signup-progress__number">4</div>
								<div className="signup-progress__text">
									<Localized code="page.signup.text.step.additionalInformation" />
								</div>
							</div>

							<div className={`signup-progress__step ${step >= 5 ? 'signup-progress__step--active' : ''}`}>
								<div className="signup-progress__number">5</div>
								<div className="signup-progress__text">
									<Localized code="page.signup.text.step.subscription" />
								</div>
							</div>
						</div>

						<div className="signup">
							{companyName ? (
								<div className="signup__company">
									<p className="signup__company-name">{companyName}</p>
								</div>
							) : null}

							<div className="signup__form">
								<form className="form">
									{threeDSSetupInProgress && (
										<div className="form__message form__message--progress">
											<ReactLoading className="form__message-loading" type="spokes" color="#002652" height="36px" width="36px" />
											<Localized code="generic.text.threeDSInProgress" />
										</div>
									)}

									{!!error ? (
									<div className="form__message form__message--error">{error}</div>
									) : null}

									{!!success ? (
									<div className="form__section">
										<h4 className="form__title">
											<Localized code="page.signup.subtitle.success" />
										</h4>

										<p className="form__text">
											<Localized code="page.signup.text.success" />
										</p>

										<p className="form__text">
											<Link to="/account/signin" className="button button--gold">
												<Localized code="page.signup.link.signIn" />
											</Link>
										</p>
									</div>
									) : null}

									{!success && !registrationOpen ? (
									<div className="form__section">
										{verifyCompanyEmailOpen ? (
											<Fragment>
												<p className="form__text form__text--center">
													<Localized code="page.signup.text.companyEmailVerificationRequired" />
												</p>

												{!companyEmailVerified ? (
													<Fragment>
														<p className="form__text">
															<Localized code="page.signup.text.companyEmailVerification" />
														</p>

														<div className="form__row">
															<div className="form__column">
																<div className="form__group">
																	<label className="form__label" htmlFor="companyEmail">
																		<Localized code="page.signup.label.companyEmail" />
																	</label>
																	<input className="form__input" type="text" id="companyEmail" value={companyEmail} onChange={this.handleCompanyEmailChange} />
																	<FormError errors={errors} field="companyEmail" />
																	{companyDomain ? (
																		<p className="form__input-help">
																			<Localized code="page.signup.text.companyEmailVerificationDomain" domain={companyDomain} />
																		</p>
																	) : null}
																</div>
															</div>

															<div className="form__column form__column--button-only">
																<button disabled={!companyEmail || emailResendTimer > 0} className="form__button form__button--small form__button--blue" type="button" onClick={this.handleSendEmailVerificationCode}>
																	<Localized code="page.signup.button.sendEmail" />
																	{emailResendTimer > 0 ? ' (' + emailResendTimer + ')' : '' }
																</button>
															</div>
														</div>

														{companyEmailVerificationId ? (
															<Fragment>
																<p className="form__text">
																	<Localized code="page.signup.text.companyEmailVerificationEnterCode" />
																</p>

																<div className="form__row">
																	<div className="form__column form__column--half">
																		<div className="form__group">
																			<label className="form__label" htmlFor="emailCode">
																				<Localized code="page.signup.label.emailCode" />
																			</label>
																			<input className="form__input" type="text" id="emailCode" value={emailCode} onChange={this.handleEmailCodeChange} />
																		</div>
																	</div>
																</div>
															</Fragment>
														) : null}
													</Fragment>
												) : null}

												{companyEmailVerified ? (
												<p className="form__text">
													<Localized code="page.signup.text.companyEmailAlreadyVerified" />
												</p>
												) : null}

												<div className="form__buttons">
													<button className="form__button form__button--small form__button--blue" type="button" onClick={() => this.handleGoToStep(1)}>
														<FontAwesomeIcon icon={faArrowLeft} />
													</button>

													<button disabled={!companyEmailVerificationId || (!emailCode && !companyEmailVerified) || loading} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleCompanyEmailVerificationProceed}>
														{loading ? (
															<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
														) : null}
														<Localized code="page.signup.button.proceed" />
													</button>
												</div>
											</Fragment>
										) : (
											<Fragment>
												<p className="form__text form__text--center">
													<Localized code="page.signup.text.begin" />
												</p>

												<div className="form__row">
													<div className="form__column form__column--half form__column--center">
														<div className="form__group">
															<label className="form__label form__label--center" htmlFor="companyCode">
																<Localized code="page.signup.label.companyCode" />
															</label>
															<input className="form__input" type="text" id="companyCode" name="companyCode" value={companyCode} onChange={this.handleCompanyCodeChange} />
														</div>
													</div>
												</div>

												<div className="form__buttons">
													<button disabled={!!!companyCode || companyCode.trim().length === 0} className="form__button form__button--gold" type="submit" onClick={this.handleCompanyCodeProceed}>
														<Localized code="page.signup.button.proceed" />
													</button>
												</div>
											</Fragment>
										)}
									</div>
									) : null}
									
									{!success && registrationOpen && personalInformationOpen ? (
									<div className="form__section">
										<h4 className="form__title">
											<Localized code="page.signup.subtitle.personalInformation" />
										</h4>

										<div className="form__row">
											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="email">
														<Localized code="page.signup.label.email" />
													</label>
													<input dir="ltr" className="form__input" type="email" id="email" value={email} onChange={this.handleEmailChange} />
													<FormError errors={errors} field="email" />
												</div>
											</div>

											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="mobile">
														<Localized code="page.signup.label.mobile" />
													</label>
													<InputMask dir="ltr" className="form__input" type="text" id="mobile" value={mobile} onChange={this.handleMobileChange} mask="+\971 5a 999 9999" formatChars={{9: '[0-9]', a: '[0|2|4|5|6|8]'}} />
													<FormError errors={errors} field="mobile" />
												</div>
											</div>

											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="emirate">
														<Localized code="page.signup.label.emirate" />
													</label>
													<div className="form__select-wrap">
														<select className="form__select" id="emirate" value={emirate} onChange={this.handleEmirateChange}>
															<option value="">{translateMessage('generic.label.pleaseSelect')}</option>
															{Emirates.map(emirate => (
																<option key={emirate.id} value={emirate.id}>{locale === 'ar' ? emirate.nameAr : emirate.name}</option>
															))}
														</select>
														<FormError errors={errors} field="emirate" />
													</div>
												</div>
											</div>
										</div>

										<div className="form__row">
											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="emiratesId">
														<Localized code="page.signup.label.emiratesId" />
													</label>
													<InputMask dir="ltr" className="form__input" type="text" id="emiratesId" value={emiratesId} onChange={this.handleEmiratesIdChange} mask="784-9999-9999999-9" />
													<FormError errors={errors} field="emiratesId" />
												</div>
											</div>

											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="emiratesIdExpiry">
														<Localized code="page.signup.label.emiratesIdExpiry" />
													</label>
													<DatePicker
														dateFormat="dd-MM-yyyy"
														selected={emiratesIdExpiryDate}
														onChange={this.handleEmiratesIdExpiryChange}
														customInput={
															<CustomDateInput dir="ltr" type="text" id="emiratesIdExpiry" />
														}
														className="form__input"
														placeholderText="DD/MM/YYYY"
														showMonthDropdown
														showYearDropdown
														dropdownMode="select"
														minDate={new Date()}
													/>
													<FormError errors={errors} field="emiratesIdExpiry" />
												</div>
											</div>

											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="dob">
														<Localized code="page.signup.label.dob" />
													</label>
													<DatePicker
														dateFormat="dd-MM-yyyy"
														selected={dobDate}
														onChange={this.handleDobChange}
														customInput={
															<CustomDateInput dir="ltr" type="text" id="dob" />
														}
														className="form__input"
														placeholderText="DD/MM/YYYY"
														showMonthDropdown
														showYearDropdown
														dropdownMode="select"
														maxDate={new Date()}
													/>
													<FormError errors={errors} field="dob" />
												</div>
											</div>
										</div>

										<div className="form__row">
											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="language">
														<Localized code="page.signup.label.preferredLanguage" />
													</label>
													<div className="form__select-wrap">
														<select className="form__select" id="language" value={language} onChange={this.handleLanguageChange}>
															<option value="">{translateMessage('generic.label.pleaseSelect')}</option>
															{languages.map(language => (
																<option key={language.id} value={language.id}>{language.languageName}</option>
															))}
														</select>
														<FormError errors={errors} field="language" />
													</div>
												</div>
											</div>

											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="password">
														<Localized code="page.signup.label.password" />
													</label>
													<input className="form__input" type="password" id="password" value={secret} onChange={this.handleSecretChange} />
													<FormError errors={errors} field="secret" />
													<div className="password-strength">
														<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleLowercase)}`}>
															<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleLowercase)} />
															<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleLowercase" /></span>
														</div>
														<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleUppercase)}`}>
															<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleUppercase)} />
															<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleUppercase" /></span>
														</div>
														<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleNumber)}`}>
															<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleNumber)} />
															<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleNumber" /></span>
														</div>
														<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleSpecial)}`}>
															<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleSpecial)} />
															<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleSpecial" /></span>
														</div>
														<div className={`password-strength__rule ${this.getPasswordRuleClass(passwordRuleLength)}`}>
															<FontAwesomeIcon className="password-strength__rule-icon" icon={this.getPasswordRuleIcon(passwordRuleLength)} />
															<span className="password-strength__rule-text"><Localized code="page.password.text.passwordRuleLength" /></span>
														</div>
													</div>
												</div>
											</div>

											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="passwordConfirm">
														<Localized code="page.signup.label.passwordConfirm" />
													</label>
													<input className="form__input" type="password" id="passwordConfirm" value={secretConfirm} onChange={this.handleSecretConfirmChange} />
													<FormError errors={errors} field="secretConfirm" />
												</div>
											</div>
										</div>

										<div className="form__buttons">
											<button className="form__button form__button--small form__button--blue" type="button" onClick={() => this.handleGoToStep(1)}>
												<FontAwesomeIcon icon={faArrowLeft} />
											</button>

											<button disabled={personalProceedDisabled || loading} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handlePersonalProceed}>
												{loading ? (
													<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
												) : null}
												<Localized code="page.signup.button.proceed" />
											</button>
										</div>
									</div>
									) : null}

									{!success && registrationOpen && smsVerificationOpen ? (
										<div className="form__section">
											<h4 className="form__title">
												<Localized code="page.signup.subtitle.smsVerification" />
											</h4>

											{!mobileVerified ? (
												<Fragment>
													<p className="form__text">
														<Localized code="page.signup.text.smsVerification" />
													</p>

													<div className="form__row">
														<div className="form__column">
															<div className="form__group">
																<label className="form__label" htmlFor="smsCode">
																	<Localized code="page.signup.label.smsCode" />
																</label>
																<input className="form__input" type="text" id="smsCode" value={smsCode} onChange={this.handleSmsCodeChange} />
															</div>
														</div>

														<div className="form__column form__column--button-only">
															<button disabled={smsResendTimer > 0} className="form__button form__button--small form__button--blue" type="button" onClick={this.handleResendSmsVerificationCode}>
																<Localized code="page.signup.button.resendSMS" />
																{smsResendTimer > 0 ? ' (' + smsResendTimer + ')' : '' }
															</button>
														</div>
													</div>
												</Fragment>
											) : null}

											{mobileVerified ? (
											<p className="form__text">
												<Localized code="page.signup.text.smsAlreadyVerified" />
											</p>
											) : null}

											<div className="form__buttons">
												<button className="form__button form__button--small form__button--blue" type="button" onClick={() => this.handleGoToStep(2)}>
													<FontAwesomeIcon icon={faArrowLeft} />
												</button>

												<button disabled={(!smsCode && !mobileVerified) || loading} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleSmsVerificationProceed}>
													{loading ? (
														<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
													) : null}
													<Localized code="page.signup.button.proceed" />
												</button>
											</div>
										</div>
									) : null}

									{!success && registrationOpen && additionalInformationOpen ? (
									<div className="form__section">
										<h4 className="form__title">
											<Localized code="page.signup.subtitle.additionalInformation" />
										</h4>

										<div className="form__row">
											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="employeeNo">
														<Localized code="page.signup.label.employeeNumber" />
													</label>
													<input className="form__input" type="text" pattern="[0-9]*" id="employeeNo" value={employeeNo} onChange={this.handleEmployeeNoChange} />
												</div>
											</div>

											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="gender">
														<Localized code="page.signup.label.gender" />
													</label>
													<div className="form__select-wrap">
														<select className="form__select" id="gender" value={gender} onChange={this.handleGenderChange}>
															<option value="">{translateMessage('generic.label.pleaseSelect')}</option>
															<option value="1">{translateMessage('generic.label.gender.male')}</option>
															<option value="2">{translateMessage('generic.label.gender.female')}</option>
														</select>
													</div>
												</div>
											</div>
										</div>

										<div className="form__row">
											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="nameAr">
														<Localized code="page.signup.label.nameInArabic" />
													</label>
													<input pattern="[أ-ي ]+" className="form__input" type="text" dir="rtl" id="nameAr" value={nameAr} onChange={this.handleNameArabicChange} />
													<FormError errors={errors} field="nameAr" />
												</div>
											</div>

											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="nameEn">
														<Localized code="page.signup.label.nameInEnglish" />
													</label>
													<input pattern="[a-zA-Z ]+" dir="ltr" className="form__input" type="text" id="nameEn" value={nameEn} onChange={this.handleNameEnglishChange} />
													<FormError errors={errors} field="nameEn" />
												</div>
											</div>
										</div>

										<div className="form__row">
											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="personalFunds">
														<Localized code="page.signup.label.personalFunds" />
													</label>
													<div className="form__select-wrap">
														<select className="form__select" id="personalFunds" value={personalFunds} onChange={this.handlePersonalFundsChange}>
															<option value="">{translateMessage('generic.label.pleaseSelect')}</option>
															<option value="1">{translateMessage('page.signup.label.personalFunds.other')}</option>
															<option value="2">{translateMessage('page.signup.label.personalFunds.salary')}</option>
														</select>
													</div>
												</div>
											</div>

											<div className="form__column">
												<div className="form__group">
													<label className="form__label">
														<Localized code="page.signup.label.monthlySalary" />
													</label>
													<div className="form__radios">
														<FormRadioButton name="salary" value="3" label={translateMessage('page.signup.label.monthlySalary.upto10000')} current={salary} onChange={this.handleSalaryChange} />
														<FormRadioButton name="salary" value="4" label={translateMessage('page.signup.label.monthlySalary.upto25000')} current={salary} onChange={this.handleSalaryChange} />
														<FormRadioButton name="salary" value="5" label={translateMessage('page.signup.label.monthlySalary.upto50000')} current={salary} onChange={this.handleSalaryChange} />
														<FormRadioButton name="salary" value="6" label={translateMessage('page.signup.label.monthlySalary.above50000')} current={salary} onChange={this.handleSalaryChange} />
													</div>
												</div>
											</div>
										</div>

										<div className="form__row">
											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="nationality">
														<Localized code="page.signup.label.nationality" />
													</label>
													<div className="form__select-wrap">
														<select className="form__select" id="nationality" value={nationality} onChange={this.handleNationalityChange}>
															<option value="">{translateMessage('generic.label.pleaseSelect')}</option>
															{countries.map(country => (
																<option key={country.id} value={country.id}>{locale === 'ar' ? country.countryNameAr : country.countryName}</option>
															))}
														</select>
													</div>
												</div>
											</div>

											<div className="form__column">
												<div className="form__group">
													<label className="form__label" htmlFor="nameFazaa">
														<Localized code="page.signup.label.nameOnFazaaCard" />
													</label>
													<input pattern="[a-zA-Z ]+" maxLength="26" className="form__input" type="text" id="nameFazaa" value={nameFazaa} onChange={this.handleNameFazaaChange} />
													<FormError errors={errors} field="nameFazaa" />
												</div>

												{nameFazaa ? (
													<div ref={this.cardPreviewRef} className="card-preview">
														<img className="card-preview__image" src={imgCardPreview} />
														<span style={{ fontSize: cpFontSize + 'px', top: cpTop + 'px', left: cpLeft + 'px' }} className="card-preview__text">{nameFazaa}</span>
													</div>
												) : null}
											</div>
										</div>

										<div className="form__buttons">
											<button className="form__button form__button--small form__button--blue" type="button" onClick={() => this.handleGoToStep(2)}>
												<FontAwesomeIcon icon={faArrowLeft} />
											</button>

											<button disabled={additionalProceedDisabled} className="form__button form__button--gold" type="submit" onClick={this.handleAdditionalInformationProceed}>
												<Localized code="page.signup.button.proceed" />
											</button>
										</div>
									</div>
									) : null}

									{!success && registrationOpen && plansPaymentOpen ? (
									<div className="form__section">
										<h4 className="form__title">
											<Localized code="page.signup.subtitle.subscription" />
										</h4>

										<div className="form__group">
											<label className="form__label">
												<Localized code="page.signup.label.subscriptionPeriod" />
											</label>
											<div className="form__radios form__radios--inline">
												{subscriptions.filter(s => s.subscriptionMonths === 12).map(s => (
													<FormRadioButton key={s.id} name="subscription" value={s.id} label={locale === 'ar' ? s.subscriptionNameAr : s.subscriptionNameEn} current={subscription} onChange={this.handleSubscriptionChange} />	
												))}
											</div>
										</div>

										<div className="form__group">
											<label className="form__label">
												<Localized code="page.signup.label.cardLevel" />
											</label>
											<div className="signup__levels">
												{levels.filter(l => ((l.id !== 4) || (l.id === 4 && allowDiscount)) && (lastCheckedCompanyCode && lastCheckedCompanyCode.companyLevelId <= l.id)).map(l => (
													<div key={l.id} className={`signup__level signup__level--${l.levelName.split(' ')[0].toLowerCase()} ${this.levelClasses(l.id)}`} onClick={() => this.handleLevelChange(l.id)}>
														<h4 className="signup__level-title">
															<FontAwesomeIcon icon={faCheck} className="signup__level-icon" />
															{l.levelName.split(' ')[0]}
														</h4>

														{false ? (
															<p className="signup__level-price">
																<Localized code="page.signup.text.amountPerMonth" amount={freeMembership ? 0 : l.levelAmount} />
															</p>
														) : (
															<p className="signup__level-price">
																<Localized code="page.signup.text.amountPerYear" amount={freeMembership ? 0 : (l.levelAmount * 12)} />
															</p>
														)}

														{content['levelFeatures' + l.levelName.split(' ')[0]] ? (
														<div className="signup__level-description cms-generated" dangerouslySetInnerHTML={{__html: content['levelFeatures' + l.levelName.split(' ')[0]].localData[locale].contentValue}} />
														) : null}

														{subscriptionInfo ? (
														<Fragment>
															<p className="signup__level-total">
																<Localized code="page.signup.text.total" />
															</p>
															<p className="signup__level-total-amount">{(freeMembership ? 0 : l.levelAmount) * subscriptionInfo.subscriptionMonths} AED</p>
															<p className="signup__repeat">
																<Localized code="page.signup.text.fragment.every" period={locale === 'ar' ? subscriptionInfo.subscriptionNameAr : subscriptionInfo.subscriptionNameEn} />
															</p>
														</Fragment>
														) : null}
													</div>
												))}
											</div>
										</div>

										{level !== 4 && !freeMembership ? (
											<Fragment>
												<h4 className="form__title">
													<Localized code="page.signup.subtitle.billingDetails" />
												</h4>

												<div className="form__row">
													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="address">
																<Localized code="page.cart.label.address" />
															</label>
															<input className="form__input" type="text" id="address" value={address} onChange={this.handleAddressChange} />
														</div>
													</div>

													{false ? (
														<div className="form__column form__column--quarter">
															<div className="form__group">
																<label className="form__label" htmlFor="postalCode">
																	<Localized code="page.cart.label.postalCode" />
																</label>
																<input className="form__input" type="text" id="postalCode" value={postalCode} onChange={this.handlePostalCodeChange} />
															</div>
														</div>
													) : null}
												</div>

												<div className="form__row">
													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="country">
																<Localized code="page.cart.label.country" />
															</label>
															<div className="form__select-wrap">
																<select className="form__select" id="country" value={country} onChange={this.handleCountryChange}>
																	<option value="">{translateMessage('generic.label.pleaseSelect')}</option>
																	{countries.map(country => (
																		<option key={country.countryCode2c} value={country.countryCode2c}>{locale === 'ar' ? country.countryNameAr : country.countryName}</option>
																	))}
																</select>
															</div>
														</div>
													</div>

													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="city">
																<Localized code="page.cart.label.city" />
															</label>
															<input className="form__input" type="text" id="city" value={city} onChange={this.handleCityChange} />
														</div>
													</div>
												</div>

												<h4 className="form__title">
													<Localized code="page.signup.subtitle.paymentDetails" />
												</h4>

												<div className="form__row">
													<div className="form__column form__column--quarter">
														<div className="form__group">
															<label className="form__label" htmlFor="firstName">
																<Localized code="page.cart.label.firstName" />
															</label>
															<input className="form__input" type="text" id="firstName" value={firstName} onChange={this.handleFirstNameChange} />
														</div>
													</div>

													<div className="form__column form__column--quarter">
														<div className="form__group">
															<label className="form__label" htmlFor="lastName">
																<Localized code="page.cart.label.lastName" />
															</label>
															<input className="form__input" type="text" id="lastName" value={lastName} onChange={this.handleLastNameChange} />
														</div>
													</div>

													<div className="form__column">
														<div className="form__group">
															<label className="form__label">
																<Localized code="page.signup.label.ccDetails" />
															</label>
															<CreditCardInput
																containerClassName="form__cc-container"
																fieldClassName="form__cc-field"
																inputClassName="form__cc-input"
																dangerTextClassName="form__field-error"
																cardNumberInputProps={{ value: ccNumber, onChange: this.handleCcNumberChange }}
																cardExpiryInputProps={{ value: ccExpiry, onChange: this.handleCcExpiryChange }}
																cardCVCInputProps={{ value: ccCvv, onChange: this.handleCcCvvChange }}
															/>
														</div>
													</div>
												</div>
											</Fragment>
										) : null}

										<div className="form__group">
											<label className="form__label">
												<Localized code="page.signup.label.terms" />
											</label>

											<div className="signup__terms-wrapper">
												{content.signUpTos ? (
												<div className="signup__terms cms-generated" dangerouslySetInnerHTML={{__html: content.signUpTos.localData[locale].contentValue}} />
												) : null}
											</div>
											
											<div className="form__checkboxes">
												<FormCheckboxButton name="terms" value="1" label={translateMessage('page.signup.label.acceptTerms', {
													__links: {
														link1: <Link className="form__label-link" to="/page/tnc" target="_blank" />
													}
												})} checked={terms} onChange={this.handleTermsChange} />
											</div>

											{level !== 4 && !freeMembership && (
											<div className="form__checkboxes">
												<FormCheckboxButton name="termsRefunds" value="1" label={translateMessage('page.signup.label.acceptNoRefunds', {
													__links: {
														link1: <Link className="form__label-link" to="/page/tnc" target="_blank" />
													}
												})} checked={termsRefunds} onChange={this.handleTermsRefundsChange} />
											</div>
											)}
										</div>

										<div className="form__buttons">
											<button className="form__button form__button--small form__button--blue" type="button" onClick={() => this.handleGoToStep(4)}>
												<FontAwesomeIcon icon={faArrowLeft} />
											</button>

											<button disabled={signUpDisabled || loading || threeDSSetupInProgress} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleSignUpClick}>
												{loading ? (
													<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
												) : null}
												<Localized code="page.signup.button.signUp" />
											</button>
										</div>
									</div>
									) : null}
								</form>
							</div>
						</div>
					</div>

					<Modal
						isOpen={this.state.challengeOpen}
						overlayClassName="modal"
						className="modal__content modal__content--challenge"
						ariaHideApp={false}
						bodyOpenClassName="locked"
						onAfterOpen={() => {
							// Setup event listeners
							document.getElementById('step-up-iframe').addEventListener('load', this.redirectHandler);

							document.getElementById('step-up-form').submit()
						}}
					>
						<iframe id="step-up-iframe" name="step-up-iframe" height="100%" width="100%"></iframe>
						<form id="step-up-form" target="step-up-iframe" method="post" action={this.state.stepUpUrl}>
							<input type="hidden" name="JWT" value={this.state.accessToken} />
							<input type="hidden" name="MD" value="fazaa" />
						</form>
					</Modal>
				</section>
			);
		}

	}
));

export default SignUp;
