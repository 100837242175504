import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';

import imgIconFacebook from '../../../assets/img/icon-facebook-2.png';
import imgIconInstagram from '../../../assets/img/icon-instagram-2.png';
import imgIconYoutube from '../../../assets/img/icon-youtube-2.png';
import imgIconTwitter from '../../../assets/img/icon-twitter-2.png';
import imgIconSnapchat from '../../../assets/img/icon-snapchat-2.png';
import imgSocialSecurityFund from '../../../assets/img/logo-social-security-fund.png';
import imgIconPlayStore from '../../../assets/img/icon-google-play.png';
import imgIconAppStore from '../../../assets/img/icon-app-store.png';
import imgIconAppGallery from '../../../assets/img/icon-appgallery.png';
import imgLogoISO from '../../../assets/img/logo-iso.png';

import Localized from '../i18n/Localized';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { faFlag } from '@fortawesome/free-regular-svg-icons';
import { autorun } from 'mobx';

const ZENDESK_SCRIPTS = {
  en: 'https://static.zdassets.com/ekr/snippet.js?key=1c23b6e1-fe14-4fea-a437-434167903fa1',
  ar: 'https://static.zdassets.com/ekr/snippet.js?key=8d87fb29-a1b3-40b2-b036-e56f04ba57f0'
};

const Footer = inject('rootStore')(withRouter(observer(
	class Footer extends Component {

		constructor(props) {
			super(props);

			this.state = {
				floatVisible: window.pageYOffset > 0
			};

			this.scrollListener = this.scrollListener.bind(this);
			this.setFooterRef = this.setFooterRef.bind(this);
			this.scrollToTop = this.scrollToTop.bind(this);
			this.goToChat = this.goToChat.bind(this);
			this.goToReportIssue = this.goToReportIssue.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { locale } = props.rootStore.commonStore;

				this.loadZendeskScript(locale);
			});
		}
		
		componentDidMount() {
			const { contentStore } = this.props.rootStore;

			contentStore.loadContent('footerMottoMessage', 'footerAddress');

			// Add scroll event listener
			window.addEventListener('scroll', this.scrollListener);
		}

		componentWillUnmount() {
			// Remove scroll event listener
			window.removeEventListener('scroll', this.scrollListener);

			this.mobxAutorunDisposer();

			this.removeZendeskScript();
		}

		scrollListener(e) {
			const { floatVisible } = this.state;

			if (window.pageYOffset > 0 && !floatVisible) {
				this.setState({
					floatVisible: true
				});
			}

			if (window.pageYOffset === 0 && floatVisible) {
				this.setState({
					floatVisible: false
				});
			}
		}

		setFooterRef(element) {
			const { commonStore } = this.props.rootStore;

			commonStore.footerRef = element;
		}

		scrollToTop() {
			requestAnimationFrame(() => {
				document.documentElement.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			});
		}

		goToChat() {
			this.props.history.push('/chat');
		}

		goToReportIssue() {
			this.props.history.push('/page/report-issue');
		}

		// ZENDESK
		loadZendeskScript(locale) {
			// Remove any existing script
			this.removeZendeskScript();
	
			// Create and append the new script
			const script = document.createElement('script');

			script.src = ZENDESK_SCRIPTS[locale] || ZENDESK_SCRIPTS.en; // Fallback to English
			script.id = 'ze-snippet';
			script.async = true;
	
			document.body.appendChild(script);
		}

		removeZendeskScript() {
			// Remove the script tag
			const existingScript = document.getElementById('ze-snippet');

			if (existingScript) {
				existingScript.remove();
			}

			// Remove the div following iframe
			const widgetDiv = document.querySelector('iframe[data-product="web_widget"] + div');
			
			if (widgetDiv) {
				widgetDiv.parentNode.removeChild(widgetDiv);
			}

			// Remove the iframe widget
			const iframe = document.querySelector('iframe[data-product="web_widget"]');

			if (iframe) {
				iframe.parentNode.removeChild(iframe);
			}

			// Clear Zendesk global variables
			delete window.zE;
			delete window.zEmbed;
			delete window.zEACLoaded;
			delete window.zEWebPackACJsonp;
		}

		render() {
			const { locale } = this.props.rootStore.commonStore;
			const { /*searchStore,*/ contentStore } = this.props.rootStore;
			//const entertainmentCategory = searchStore.availableCategories.find(ac => ac.categoryInternalName === 'Entertainment');
			const footerMottoMessage = contentStore.getContent('footerMottoMessage');
			const footerAddress = contentStore.getContent('footerAddress');
			const { floatVisible } = this.state;

			return (
				<footer className="footer" ref={this.setFooterRef}>
					{footerMottoMessage ? (
					<div className="motto footer__motto">
						<h3 className="motto__title">
							<span className="motto__title-text">What we are</span>
						</h3>

						<p className="motto__text">
							{footerMottoMessage.localData[locale].contentValue}
						</p>
					</div>
					) : null}

					<div className="footer__content">
						<div className="footer__content-inner">
							<div className="footer-section footer__more-links">
								<h6 className="footer-section__title">
									<span className="footer-section__title-text">
										<Localized code="footer.moreLinks.title" />
									</span>
								</h6>

								<ul className="footer-section__menu">
									<li className="footer-section__menu-item">
										<Link className="footer-section__menu-link" to="/page/benefits">
											<Localized code="footer.link.benefits" />
										</Link>
									</li>

									{false ? (
										<li className="footer-section__menu-item">
											<Link className="footer-section__menu-link" to="/page/members">
												<Localized code="footer.link.members" />
											</Link>
										</li>
									) : null}

									<li className="footer-section__menu-item">
										<Link className="footer-section__menu-link" to="/page/become-fazaa-partner">
											<Localized code="footer.link.partners" />
										</Link>
									</li>

									{false && (
										<li className="footer-section__menu-item">
											<Link className="footer-section__menu-link" to="/page/topups">
												<Localized code="footer.link.topups" />
											</Link>
										</li>
									)}

									<li className="footer-section__menu-item">
										<Link className="footer-section__menu-link" to="/services/view/long-term-car-lease">
											<Localized code="footer.link.availableCars" />
										</Link>
									</li>

									<li className="footer-section__menu-item">
										<Link className="footer-section__menu-link" to="/services/view/fazaa-stores">
											<Localized code="footer.link.fazaaStores" />
										</Link>
									</li>

									{false ? (
										<li className="footer-section__menu-item">
											<Link className="footer-section__menu-link" to="/">
												<Localized code="footer.link.contactUs" />
											</Link>
										</li>
									) : null}
								</ul>
							</div>

							<div className="footer-section footer__location">
								<h6 className="footer-section__title">
									<span className="footer-section__title-text">
										<Localized code="footer.contact.title" />
									</span>
								</h6>

								{footerAddress ? (
									<address className="footer-section__text" dangerouslySetInnerHTML={{__html: footerAddress.localData[locale].contentValue }}></address>
								) : null}
							</div>

							<div className="footer-section footer__sns-apps">
								<h6 className="footer-section__title">
									<span className="footer-section__title-text">
										<Localized code="footer.followUs.title" />
									</span>
								</h6>

								<ul className="footer__sns">
									<li className="footer__sns-item">
										<a target="_blank" rel="noopener noreferrer" className="footer__sns-link" href="https://instagram.com/fazaa4all">
											<img className="footer__sns-image" src={imgIconInstagram} alt="Instagram" title="Instagram" />
										</a>
									</li>

									<li className="footer__sns-item">
										<a target="_blank" rel="noopener noreferrer" className="footer__sns-link" href="https://www.snapchat.com/add/fazaa4all/QNg5T">
											<img className="footer__sns-image" src={imgIconSnapchat} alt="Snapchat" title="Snapchat" />
										</a>
									</li>

									<li className="footer__sns-item">
										<a target="_blank" rel="noopener noreferrer" className="footer__sns-link" href="https://facebook.com/fazaa4all">
											<img className="footer__sns-image" src={imgIconFacebook} alt="Facebook" title="Facebook" />
										</a>
									</li>

									<li className="footer__sns-item">
										<a target="_blank" rel="noopener noreferrer" className="footer__sns-link" href="https://twitter.com/fazaa4all">
											<img className="footer__sns-image" src={imgIconTwitter} alt="Twitter" title="Twitter" />
										</a>
									</li>

									<li className="footer__sns-item">
										<a target="_blank" rel="noopener noreferrer" className="footer__sns-link" href="https://www.youtube.com/channel/UCDzg51SeyyD7QhVx_cY-nSw">
											<img className="footer__sns-image" src={imgIconYoutube} alt="YouTube" title="YouTube" />
										</a>
									</li>
								</ul>

								<p className="footer__sns-name" dir="ltr">@Fazaa4all</p>

								<div className="footer__apps">
									<h6 className="footer__apps-title">
										<Localized code="footer.downloadApp.title" />
									</h6>

									<div className="footer__apps-stores">
										<a className="footer__apps-store-link" href="https://itunes.apple.com/ae/app/fazaaae/id1049790992?mt=8">
											<img className="footer__apps-store-image" src={imgIconAppStore} alt="Apple App Store" title="Apple App Store" />
										</a>

										<a className="footer__apps-store-link" href="https://play.google.com/store/apps/details?id=ae.fazaa">
											<img className="footer__apps-store-image" src={imgIconPlayStore} alt="Google Play Store" title="Google Play Store" />
										</a>

										<a className="footer__apps-store-link" href="https://appgallery8.huawei.com/#/app/C102634207">
											<img className="footer__apps-store-image" src={imgIconAppGallery} alt="Huawei AppGallery" title="Huawei AppGallery" />
										</a>
									</div>
								</div>

								<div className="footer__iso">
									<img className="footer__iso-image" src={imgLogoISO} alt="ISO" title="ISO" />
								</div>
							</div>

							{false && (
								<div className="footer-section footer__ssf">
									<a className="footer__ssf-link" target="_blank" rel="noopener noreferrer" href="https://www.moi.gov.ae">
										<img className="footer__ssf-image" src={imgSocialSecurityFund} alt="Social Security Fund - Ministry of Interior" title="Social Security Fund - Ministry of Interior" />
									</a>
								</div>
							)}
						</div>
					</div>

					<div className="copyright footer__copyright">
						<p className="copyright__text">
							<Localized code="footer.copyright" year={new Date().getFullYear()} />
						</p>

						<div className="copyright__links">
							<Link className="copyright__link" to="/page/terms-and-conditions">
								<Localized code="footer.link.terms" />
							</Link>

							<Link className="copyright__link" to="/page/privacy">
								<Localized code="footer.link.privacy" />
							</Link>

							<Link className="copyright__link" to="/page/quality-policy">
								<Localized code="footer.link.qualityPolicy" />
							</Link>
						</div>
					</div>

					<button className={`footer__float footer__float--arrow-top ${floatVisible ? '' : 'footer__float--hidden'}`} onClick={this.scrollToTop}>
						<div className="footer__arrow-top" />
					</button>

					{false && (
						<button className="footer__float footer__float--report" onClick={this.goToReportIssue}>
							<FontAwesomeIcon className="footer__report" icon={faFlag} />
						</button>
					)}

					{false && (
						<button className="footer__float footer__float--chat" onClick={this.goToChat}>
							<FontAwesomeIcon className="footer__chat" icon={faCommentDots} />
						</button>
					)}
				</footer>
			);
		}

	}
)));

export default Footer;
